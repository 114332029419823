import { collection, doc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { auth, db, functions } from '../firebase-config';
import { Employee } from '../types/types';
import { sendPasswordResetEmail } from 'firebase/auth';
import { httpsCallable, HttpsCallableResult } from 'firebase/functions';

const getAllEmployees = async () => {
  const employees: Employee[] = [];
  const querySnapshot = await getDocs(collection(db, 'employees'));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- has to be any so can add types to firebase data
  querySnapshot.forEach((doc: any) => {
    const docID: string = doc.id;
    const data: Employee = { ...doc.data() };

    data.uid = docID;
    employees.push(data);
  });

  return employees;
};

const createEmployee = async (newEmployee: Employee, uid: string) => {
  // first we must create the login credentials
  try {
    const employeeRef = doc(db, 'employees', uid);
    await setDoc(employeeRef, newEmployee);
    return true;
  } catch (error) {
    return false;
  }
};

const getStoreEmployees = async (user: Employee) => {
  const employees: Employee[] = [];
  const q = query(
    collection(db, 'employees'),
    where('storeID', '==', user.storeID),
    where('status', 'in', ['permanent-employee', 'temporary-employee']),
  );
  // const q2 = query(
  //   collection(db, 'employees'),
  //   where('status', 'in', ['permanent-employee', 'temporary-employee']),
  // );
  const querySnapshot = await getDocs(q);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- has to be any so can add types to firebase data
  querySnapshot.forEach((doc: any) => {
    const docID: string = doc.id;
    const data: Employee = { ...doc.data() };
    data.uid = docID;
    employees.push(data);
  });

  return employees;
};

const updateEmployee = async ({ docID, emp }: { docID: string; emp: Employee }) => {
  try {
    const docRef = doc(db, 'employees', docID);
    // const data = { ...user };

    return updateDoc(docRef, {
      phone: emp.phone,
      roles: emp.roles,
      title: emp.title,
      status: emp.status,
    })
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  } catch {
    return false;
  }
};

const resetEmployeePassword = async (email: string) => {
  return sendPasswordResetEmail(auth, email)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

const terminateEmployee = async (uid: string) => {
  try {
    const terminateEmployeeCallable = httpsCallable(functions, 'terminateEmployee');
    // eslint-disable-next-line
    const res: HttpsCallableResult<any> = await terminateEmployeeCallable({ uid });

    const {
      data,
    }: {
      data: {
        status: string;
        message: string;
      };
    } = res;
    const { status } = data;
    return true;
  } catch (error) {
    return false;
  }
};

const manuallyResetEmployeePassword = async (uid: string, newPassword: string) => {
  try {
    const manuallyResetEmployeePasswordCallabke = httpsCallable(
      functions,
      'manuallyResetEmployeePassword',
    );
    // eslint-disable-next-line
    await manuallyResetEmployeePasswordCallabke({
      employeeUID: uid,
      newPassword,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export {
  getAllEmployees,
  createEmployee,
  getStoreEmployees,
  updateEmployee,
  resetEmployeePassword,
  terminateEmployee,
  manuallyResetEmployeePassword,
};
