const formatPhoneNumberForDB = (unformattedPhoneNumber: string) => {
  const removedPlusSign = unformattedPhoneNumber.replace('+1', '1');
  const removedLeftParanthesis = removedPlusSign.replace('(', '');
  const removedRightParanthese = removedLeftParanthesis.replace(')', '');
  const removedHyphen = removedRightParanthese.replaceAll('-', '');
  const finalFormat = removedHyphen.replaceAll(' ', '');
  return finalFormat;
};

const formatDateMMDDYYLocalTime = (dateToFormat: string) => {
  const d = new Date(dateToFormat);
  return d.toLocaleString();
};

// Used In Todo Cards
const formatDateMonthDay = (dateToFormat: string) => {
  const d = new Date(dateToFormat);
  const now = new Date();
  const month = d.getMonth();
  const day = d.getDate();
  const year = d.getFullYear();
  // If the year is a different year than this year then show the year
  // TODO might be a good idea to show year for todos that super old
  const needYear = year != now.getFullYear();

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const finalMonth = months[month] || 'Dec';

  if (needYear == false) {
    return finalMonth + ' ' + day;
  } else {
    return finalMonth + ' ' + day + ' ' + year; // This will show the year if it is a long term todo
  }
};
// Used In Date Table
const formatDateMMDDForWeek = () => {
  //  const curr = new Date; // get current date
  // const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
  // const last = first + 6; // last day is the first day + 6

  // const firstday = new Date(curr.setDate(first))
  // const lastday = new Date(curr.setDate(last))

  // const beginMonth = firstday.getMonth()
  // const beginDate = firstday.getDate()

  // const lastMonth = lastday.getMonth()
  // const lastDate = lastday.getDate()

  //    const months = [
  //   'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  //   'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  //     ];

  // const finalFirstMonth = months[beginMonth] || 'Dec';
  // const finalLastMonth = months[lastMonth] || 'Dec';

  // return finalFirstMonth + ' ' + beginDate + ' ' + '-' + ' ' +  finalLastMonth + ' ' + lastDate

  // NOTE Update from the code above the code above give back a single date based on today
  // date and formats this one gives back the date and 13 weeks in the future
  const curr = new Date(); // get the current date
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const dateRanges = [];

  for (let i = 0; i < 13; i++) {
    const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    const last = first + 6; // last day is the first day + 6

    const firstday = new Date(curr.setDate(first));
    const lastday = new Date(curr.setDate(last));

    const beginMonth = firstday.getMonth();
    const beginDate = firstday.getDate();

    const lastMonth = lastday.getMonth();
    const lastDate = lastday.getDate();

    const finalFirstMonth = months[beginMonth] || 'Dec';
    const finalLastMonth = months[lastMonth] || 'Dec';

    dateRanges.push(finalFirstMonth + ' ' + beginDate + ' - ' + finalLastMonth + ' ' + lastDate);

    // Move to the next week
    curr.setDate(curr.getDate() + 1);
  }

  // Now, dateRanges contains the date ranges for the next 13 weeks
  return dateRanges;
};

// Used In Rock Page. Output: Nov 10, 2023
function formatDateMonthDayYear(dateToFormat: string) {
  const d = new Date(dateToFormat);
  const month = d.getMonth();
  const day = d.getDate();
  const year = d.getFullYear();
  // If the year is a different year than this year then show the year
  // TODO might be a good idea to show year for todos that super old
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const finalMonth = months[month] || 'Dec';

  return finalMonth + ' ' + day + ',' + ' ' + year; // This will show the year if it is a long term todo
}

// Format Date Type to use for edit Card
const formatYYYMMDD = (dateToFormat: Date | undefined) => {
  const year = dateToFormat?.toLocaleString('default', { year: 'numeric' });
  const month = dateToFormat?.toLocaleString('default', { month: '2-digit' });
  const day = dateToFormat?.toLocaleString('default', { day: '2-digit' });
  return year + '-' + month + '-' + day;
};

const formateYesNo = (value: number | boolean) => {
  if (value === true) {
    return 'Yes';
  } else if (value === false) {
    return 'No';
  } else {
    return value;
  }
};

// auto formatting the phone number
const addHyphens = (value: string) => {
  // Remove existing hyphens and any leading/trailing whitespace
  const sanitizedInput = value.replace(/-/g, '').trim();

  let result = '';
  for (let i = 0; i < sanitizedInput.length; i++) {
    // Add a hyphen after every 3rd character, if there aren't already 2 hyphens
    if (i > 0 && i % 3 === 0 && result.split('-').length - 1 < 2) {
      result += '-';
    }
    result += sanitizedInput[i];
  }
  return result;
};

export {
  addHyphens,
  formatDateMMDDForWeek,
  formatDateMMDDYYLocalTime,
  formatDateMonthDay,
  formatDateMonthDayYear,
  formatPhoneNumberForDB,
  formatYYYMMDD,
  formateYesNo,
};
