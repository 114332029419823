/**
 * This file will have the Firebase queries for interacting with the Collection
 * users for the purpose of the Laundry Pickup Page
 */

import {
  DocumentData,
  QuerySnapshot,
  Timestamp,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../../firebase-config';
import { Employee, LaundryItem, User } from '../../types/types';
import capitalizeFirstLetter from '../../utils/capitalize-first-letter';
import formatPhoneNumber from '../../utils/format-phone-number';

interface FirebaseUser {
  firstName: string;
  lastName: string;
  lastLoggedIn: Timestamp;
  leasingItems?: boolean;
  routeDay: string[];
  status: string;
  washType: string;
  email: string;
  phoneNumber: number;
  address: string;
  city: string;
  apt: string;
  state: string;
  postalcode: string;
  notes?: string;
  storeUID?: string;
  deliveryNotes: string;
  deliveryNotesImages: { url: string; description: string }[];
  trackWetDryWeight?: boolean;
  type?: string;
  itemsList?: LaundryItem[];
  itemMeasurement?: { label: string; name: string; type: string };
  receiptPickupType?: string | null;
}

/**
 * This function serves the purpose of finding the appropraite users for the current day Pickup
 * day for the logged in employee
 * @param user
 * @param currentDay - the current work day (monday, tuesday, etc..)
 * @returns Users[] or Null- the array of users. If null then employee does not have appropriate permissions
 */
const getUsersForPickup = async (user: Employee, currentDay: string) => {
  const query = generateQueryBasedOnEmployee(user, currentDay);
  if (query !== null) {
    const querySnapshot = await getDocs(query);
    const filteredDocs = transformFirebaseUsersToFrontendUser(querySnapshot);
    return filteredDocs;
  } else {
    console.log('User does not have permission to get users for pick up');
    return null;
  }
};

// this function will create the appropriate query based on the user type
const generateQueryBasedOnEmployee = (user: Employee, currentDay: string) => {
  // check to see if they are superadmin
  if (user.status === 'super-admin') {
    return query(collection(db, 'users'), where('status', '==', 'active'));
  }
  // check to see if they are owner or manager
  if (
    user.status === 'business-owner' ||
    (user.status === 'permanent-employee' && user.title === 'manager')
  ) {
    return query(collection(db, 'users'), where('storeUID', '==', user.storeID));
  }
  // check to see if they are driver. only get the users for today
  if (user.status == 'permanent-employee' || user.title == 'driver') {
    return query(
      collection(db, 'users'),
      where('storeUID', '==', user.storeID),
      where('routeDay', 'array-contains', currentDay),
    );
  }
  // if none of them then return null
  console.log('user does not have appropriate permissions');
  return null;
};

// this function will get the querysnapshot. iterate through each doc
// create a frontend user
const transformFirebaseUsersToFrontendUser = (snapshot: QuerySnapshot<DocumentData>) => {
  const users: User[] = [];
  // eslint-disable-next-line
  snapshot.forEach((doc: any) => {
    const docID: string = doc.id;
    const {
      firstName,
      lastName,
      lastLoggedIn,
      leasingItems,
      routeDay,
      status,
      washType,
      email,
      phoneNumber,
      address,
      apt,
      city,
      state,
      postalcode,
      notes,
      deliveryNotes,
      deliveryNotesImages,
      type,
      itemsList,
      itemMeasurement,
      storeUID,
      trackWetDryWeight,
      receiptPickupType,
    }: FirebaseUser = doc.data();
    let dateJoined;
    let dateUnformatted;
    if (lastLoggedIn) {
      dateJoined = lastLoggedIn
        .toDate()
        .toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
      dateUnformatted = lastLoggedIn.toDate();
    }

    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

    let formattedWashType;
    if (washType) formattedWashType = capitalizeFirstLetter(washType);

    let formattedStatus;
    if (status) {
      if (status === 'active') formattedStatus = 'Active';
      if (status === 'inactive') formattedStatus = 'Inactive';
      if (status === 'outOfArea') formattedStatus = 'Out Of Area';
      if (status === 'loginCreated') formattedStatus = 'Login Created';
      if (status === 'terminal') formattedStatus = 'Terminal';
      if (status === 'pending') formattedStatus = 'Pending';
      if (status === 'admin') formattedStatus = 'Admin';
    }

    if (!email) return;
    const user = {
      docID: docID,
      id: email,
      fullName: `${firstName} ${lastName}`,
      dateJoined,
      dateUnformatted,
      routeDay,
      status: formattedStatus,
      washType: formattedWashType,
      email,
      phoneNumber: formattedPhoneNumber,
      phoneNumberApi: phoneNumber,
      address,
      apt,
      city,
      state,
      postalcode: postalcode,
      storeUID: storeUID ? storeUID : '',
      receiptPickupType: receiptPickupType ? receiptPickupType : null,
      trackWetDryWeight: trackWetDryWeight ? trackWetDryWeight : false,
      type: type ? type : 'residential',
      leasingItems: leasingItems ? true : false,
      ...(itemsList && { itemsList }),
      ...(itemMeasurement && { itemMeasurement }),
      ...(deliveryNotes && { deliveryNotes }),
      ...(deliveryNotesImages && { deliveryNotesImages }),
      ...(notes && { notes }), // add to object only if exists
    };

    users.push(user);
  });
  return users;
};
export { getUsersForPickup };
