import SearchIcon from '@mui/icons-material/Search';
import {
  Card,
  CardHeader,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { weekdays } from '../../constants';
import { Employee, User } from '../../types/types';
import CorporateReceipt from '../create-qr-code/components/CorporateReceipts/CorporateReceipt';
import PickupBinForm from './components/PickupBinDialog';
import s from './styles.module.scss';
import WetWeightCorporateReceipt from '../create-qr-code/components/WetWeightCorporateReceipt/WetWeightCorporateReceipt';

enum Status {
  ACTIVE = 'Active',
  PICKUPS = 'Pickups',
}

const PICKUP_DAY_OPTIONS = [
  'All',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

type Props = { data: User[]; loggedUser: Employee };

const LaundryPickup = ({ data, loggedUser }: Props) => {
  // const [users] = useState(data);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([...data]);
  const [status, setStatus] = useState<Status>(Status.ACTIVE);

  const date = new Date();
  const currentDay = weekdays[date.getDay()];
  const [day, setDay] = useState(currentDay);

  const [searchWord, setSearchWord] = useState('');

  const handleChange = (status: Status, day: string, word: string) => {
    setStatus(status);
    if (status !== Status.ACTIVE) {
      // reset day if not 'Active' status
      setDay('All');
    } else {
      setDay(day);
    }
    setSearchWord(word);
  };

  const filterMessage = (
    <Box fontWeight='normal' display='flex' alignItems='center'>
      <span className={s.highlight}>{status}</span> users with a&nbsp;
      <span className={s.highlight}>{day}</span> route day
      {searchWord && <SearchIcon color='primary' sx={{ ml: 1 }} />}
      <span className={s.highlight}>Total Count:</span>
      {filteredUsers.length}
    </Box>
  );

  // state for modal
  const [isOpen, setIsOpen] = useState(false);
  const [userSelected, setUserSelected] = useState<User | null>({} as User);

  const openModal = (user: User) => {
    setUserSelected(user);
    setIsOpen(true);
  };

  const closeModal = () => {
    setUserSelected(null);
    setIsOpen(false);
  };

  // function for generating the Route Day drop down based on Employee type
  // The following can view the route day selection:
  // super-admin, business-owner, manager
  const generateRouteDayDropdown = (employee: Employee) => {
    if (
      employee.status === 'super-admin' ||
      loggedUser.status === 'business-owner' ||
      (loggedUser.status === 'permanent-employee' && loggedUser.title === 'manager')
    ) {
      if (status === Status.ACTIVE) {
        return (
          <FormControl>
            <InputLabel>Route Day</InputLabel>
            <Select
              value={day}
              label='Route Day'
              onChange={(e) => handleChange(status, e.target.value, searchWord)}
            >
              {PICKUP_DAY_OPTIONS.map((option) => {
                return (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      }
    }
  };

  useEffect(() => {
    // filter by value unless there is no value (ex: routeDay 'All')
    const _filteredUsers = data.filter(
      (user) =>
        (user.status === status || !status) &&
        ((user.routeDay && user.routeDay.includes(day)) || day === 'All') &&
        user.fullName.toLowerCase().includes(searchWord.toLowerCase()),
    );
    setFilteredUsers(_filteredUsers);
  }, [day, status, searchWord]);

  return (
    <>
      <ToggleButtonGroup
        color='primary'
        value={status}
        onChange={(_, status: Status) => handleChange(status, day, searchWord)}
        exclusive
      >
        <ToggleButton value={Status.ACTIVE}>Active</ToggleButton>
        <ToggleButton value={Status.PICKUPS}>Pickups</ToggleButton>
      </ToggleButtonGroup>
      {/* The container for the Route days and Searchbar */}
      <Stack direction='row' alignItems='center' mt={3}>
        {generateRouteDayDropdown(loggedUser)}
        <TextField
          label='Search'
          value={searchWord}
          onChange={(e) => handleChange(status, day, e.target.value)}
          sx={{ ml: 1 }}
        />
      </Stack>
      {/* The User table will show the filtered Users*/}
      <Card sx={{ mt: 2 }}>
        <CardHeader title={filterMessage} disableTypography />
        {filteredUsers.length > 0 ? (
          <List dense={true} className={s.list}>
            {filteredUsers.map((user) => (
              <ListItem
                key={user.id}
                onClick={() => openModal(user)}
                sx={{ borderBottom: '1px solid #eaeaea', margin: 0, padding: 0 }}
              >
                <ListItemButton sx={{ padding: 2 }}>
                  <ListItemText primary={user.fullName} className={s.item} />
                  <ListItemText primary={user.address} className={s.address} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>No Users found</Typography>
        )}
      </Card>
      {isOpen && userSelected ? (
        userSelected.type &&
        userSelected.type == 'corporate' &&
        userSelected.receiptPickupType &&
        userSelected.receiptPickupType == 'itemized' &&
        userSelected.itemsList ? (
          // it is a corporate account. now we need to know wa
          <CorporateReceipt isOpen={isOpen} setIsOpen={setIsOpen} user={userSelected} />
        ) : userSelected.type &&
          userSelected.type == 'corporate' &&
          userSelected.receiptPickupType &&
          userSelected.receiptPickupType == 'wet-itemized' ? (
          <WetWeightCorporateReceipt isOpen={isOpen} setIsOpen={setIsOpen} user={userSelected} />
        ) : (
          <PickupBinForm customer={userSelected} close={closeModal} employee={loggedUser} />
        )
      ) : null}
    </>
  );
};

export default LaundryPickup;
