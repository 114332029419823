import * as React from 'react';
import Box from '@mui/material/Box';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  NativeSelect,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DataRow from './DataRow';
import DataModal from './DataModal/DataModal';
import { generateData, generateRollingWeeksHeaderArray } from '../Data';

// type Props = {
//   fakeData: {
//     id: number;
//     status: string;
//     owner: string;
//     title: string;
//     goal: number | boolean;
//     average: number;
//     total: number;
//     rollingWeeks: {
//       weekStart: Date | string;
//       weekEnd: Date | string;
//       value: number | boolean | string | null;
//     }[];
//   }[];
// };

// const dateArray = formatDateMMDDForWeek(); // Assuming this returns an array of 13 dates

const DataTable = () => {
  // NOTE this should be a copy of my master list data as a useState
  const [tableData, setTableData] = React.useState(generateData(10));
  // NOTE the goal with this is to click on a data cell and grow the table for the parent colum
  const [dataCellClickedOn, setDataCellClickedOn] = React.useState<number>(-1);
  const [moduleInView, setModuleInView] = React.useState<boolean>(false);
  const [view, setView] = React.useState<number[]>([-1, -1]);
  const [startDate, setStartDate] = React.useState<string>('');
  const [endDate, setEndDate] = React.useState<string>('');
  const [dateRangeType, setDateRangeType] = React.useState<string>('Rolling');
  // const dateArrayHeader = generateRollingWeeksHeaderArray(13);
  const [dateArrayHeader, setDateArrayHeader] = React.useState(
    generateRollingWeeksHeaderArray('Rolling', null, null),
  );

  // const [rollingWeeksHeaders, setRollingWeeksHeaders] = React.useState<Date[]>([]);

  const handleDateChange = (date: string, type: string) => {
    if (type === 'start') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handCellClick = (rowIndex: number, columnIndex: number) => {
    console.log(rowIndex, columnIndex, 'row / col');

    setView([rowIndex, columnIndex]);
  };

  const handleBlur = () => {
    setView([-1, -1]);
  };

  // const selectOptionsDateRange = (dateRangeSelected: string) => {
  //   // TODO come back and maybe a if statment to check if start and end date are empty then run these
  //   let numberOfWeeks = 0;

  //   // NOTE this is used to get YTD similar to Java getDayOfYear function
  //   const today: Date = new Date();
  //   const startOfYear: Date = new Date(today.getFullYear(), 0, 0);
  //   const diff: number = today.getTime() - startOfYear.getTime();
  //   const oneDay: number = 1000 * 60 * 60 * 24; // Number of milliseconds in a day

  //   const dayOfYear: number = Math.floor(diff / oneDay);

  //   // console.log('Day of the year (YTD):', dayOfYear);

  //   if(dateRangeSelected == 'Rolling'){
  //     setDefaultDateRange(dateRangeSelected)
  //     // setDateArrayHeader(generateRollingWeeksHeaderArray(13))
  //   } else if (dateRangeSelected == 'QTD') {
  //     setDefaultDateRange(dateRangeSelected)
  //     const qtdStartDate = new Date(today.getFullYear(), Math.floor(today.getMonth() / 3) * 3, 1);
  //     console.log(qtdStartDate);

  //     for (let i = 0; i <= today.getDate(); i++) {
  //      const date = new Date(qtdStartDate);
  //       date.setDate(qtdStartDate.getDate() + i); // Calculate dates for QTD
  //       if (date.getDay() === 0) { // Assuming Sunday is the start of a new week (0: Sunday, 1: Monday, ..., 6: Saturday)
  //         numberOfWeeks++;
  //       }
  //       // setDateArrayHeader(generateRollingWeeksHeaderArray(numberOfWeeks))

  //     }
  //     // setDateArrayHeader(generateRollingWeeksHeaderArray(numberOfWeeks))
  //   }else if (dateRangeSelected == 'Current Quarter') {

  //       console.log('Todo Current Quater');

  //   }
  //   else if (dateRangeSelected == 'YTD') {
  //     for (let i = 0; i <= dayOfYear; i++) {
  //             const date = new Date(today.getFullYear(), 0, 1);
  //             date.setDate(date.getDate() + i); // Calculate dates for YTD
  //              if (date.getDay() === 0) { // Assuming Sunday is the start of a new week (0: Sunday, 1: Monday, ..., 6: Saturday)
  //               numberOfWeeks++;
  //             }
  //           }
  //           // setDateArrayHeader(generateRollingWeeksHeaderArray(numberOfWeeks))
  //          console.log(numberOfWeeks);

  //   } else{
  //      const currentYearStartDate = new Date(today.getFullYear(), 0, 1);
  //     for (let i = 0; i <= dayOfYear; i++) {
  //       const date = new Date(currentYearStartDate);
  //       date.setDate(currentYearStartDate.getDate() + i); // Calculate dates for current year
  //       if (date.getDay() === 0) { // Assuming Sunday is the start of a new week (0: Sunday, 1: Monday, ..., 6: Saturday)
  //         numberOfWeeks++;
  //       }
  //     }
  //     // setDateArrayHeader(generateRollingWeeksHeaderArray(numberOfWeeks))
  //     console.log(numberOfWeeks);

  //   }
  // }

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     const _tableData = { ...tableData };

  //     _tableData.rollingWeeks = _tableData.rollingWeeks.filter(
  //       (week) =>
  //         (new Date(week.weekStart) >= new Date(startDate) &&
  //           new Date(week.weekStart) <= new Date(endDate)) ||
  //         (new Date(week.weekEnd) >= new Date(startDate) &&
  //           new Date(week.weekEnd) <= new Date(endDate)),
  //     );

  //     // set(_tableData);
  //     generateRollingWeeksHeaderArray(20)
  //   }
  // }, [startDate, endDate, defaultDateRange]);

  // useEffect ((defaultDateRange: string) => {
  //   dateArrayHeader(defaultDateRange)
  // }), [startDate, endDate, defaultDateRange]

  const selectOptionsDateRange = (dateRangeSelected: string) => {
    setDateRangeType(dateRangeSelected);
    setDateArrayHeader(generateRollingWeeksHeaderArray(dateRangeSelected, null, null));
  };

  const clearCustomDateRange = () => {
    setStartDate('');
    setEndDate('');
    setDateArrayHeader(generateRollingWeeksHeaderArray('Rolling', null, null));
  };

  React.useEffect(() => {
    if (startDate && endDate != '') {
      setDateArrayHeader(generateRollingWeeksHeaderArray('Custom', startDate, endDate));
    }
  }, [startDate, endDate]);

  return (
    <>
      <Box sx={{ overflowX: 'scroll', p: 1 }}>
        <Box sx={{ padding: 0, mt: 2 }}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Box display={'flex'}>
              <Typography variant='subtitle1' mx={3}>
                Weekly
              </Typography>
              <AddCircleOutlineIcon
                cursor={'pointer'}
                onClick={() => setModuleInView(!moduleInView)}
                sx={{ mt: 0.5 }}
              ></AddCircleOutlineIcon>
            </Box>
            <Box
              mr={1}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ width: 1 }}
            >
              <FormControlLabel
                control={<Switch defaultChecked />}
                label='Drag and Drop:'
                labelPlacement='start'
              />
              {/* TODO maybe insert this DateCalendar for a better look */}
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                            value={startDate}
                              // onChange={(e) => handleDateChange(e.target.value, 'start')}
                            />
                    
                  </LocalizationProvider> */}

              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ ml: 2 }}
                required
                label='Start Date'
                variant='standard'
                type={'date'}
                value={startDate}
                onChange={(e) => handleDateChange(e.target.value, 'start')}
              ></TextField>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ ml: 2 }}
                required
                label='End Date'
                variant='standard'
                type={'date'}
                value={endDate}
                onChange={(e) => handleDateChange(e.target.value, 'end')}
              ></TextField>

              {/* I feel like it should be And not OR */}
              {startDate == '' || endDate == '' ? (
                <FormControl sx={{ m: 2, minWidth: 130 }}>
                  <InputLabel
                    shrink={true}
                    required
                    variant='standard'
                    htmlFor='uncontrolled-native'
                  >
                    Default Team Interval
                  </InputLabel>
                  <NativeSelect
                    defaultValue={'Rolling'}
                    onChange={(e) => selectOptionsDateRange(e.target.value)}
                    inputProps={{
                      name: 'age',
                      id: 'uncontrolled-native',
                    }}
                  >
                    <option value={'Rolling'}>Rolling 13 Weeks</option>
                    <option value={'QTD'}>QTD</option>
                    <option value={'Current Quarter'}>Current Quarter</option>
                    <option value={'YTD'}>YTD</option>
                    <option value={'Current Year'}>Current Year</option>
                  </NativeSelect>
                </FormControl>
              ) : (
                <Button
                  sx={{ px: { xs: 17, sm: 2 }, m: { xs: 2 }, color: 'white' }}
                  variant='contained'
                  onClick={() => clearCustomDateRange()}
                >
                  Clear
                </Button>
              )}
            </Box>
          </Stack>

          {/* SECTION NEW DATA TABLE */}

          <Stack
            justifyContent={'start'}
            alignItems={'center'}
            direction={'row'}
            divider={<Divider orientation='vertical' flexItem />}
            spacing={2}
            sx={{ borderBottom: 1, borderColor: 'divider', width: 'fit-content' }}
          >
            <Divider orientation='vertical' flexItem />
            <Box>
              <HelpOutlineIcon color={'disabled'}></HelpOutlineIcon>
            </Box>
            <Box p={0.16}>
              <Typography variant='caption' color={'gray'}>
                Owner
              </Typography>
            </Box>
            <Box maxWidth={170} minWidth={170}>
              <Typography variant='caption' color={'gray'}>
                Title
              </Typography>
            </Box>
            <Box maxWidth={50} minWidth={50}>
              <Typography variant='caption' color={'gray'}>
                Goal
              </Typography>
            </Box>
            <Box maxWidth={50} minWidth={50}>
              <Typography variant='caption' color={'gray'}>
                Average
              </Typography>
            </Box>
            <Box maxWidth={50} minWidth={50}>
              <Typography variant='caption' color={'gray'}>
                Total
              </Typography>
            </Box>
            {/* SECTION for 13 weeks header */}
            {dateArrayHeader.map((date, i) => (
              <Box
                key={i}
                style={{
                  maxWidth: dataCellClickedOn === i ? '100px' : '55px',
                  minWidth: dataCellClickedOn === i ? '100px' : '55px',
                }}
              >
                <Typography variant='caption' color='gray'>
                  {date}
                </Typography>
              </Box>
            ))}
            <Divider orientation='vertical' flexItem />
          </Stack>
          {/* SECTION This where data rows begin. Headers are above */}

          {tableData.map((dataItem, rowIndex) => (
            <DataRow
              key={dataItem.id}
              dataItem={dataItem}
              setDataCellClickedOn={setDataCellClickedOn}
              handleBlur={handleBlur}
              handleCellClicked={handCellClick}
              rowIndex={rowIndex}
              view={view}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setView={setView}
              dateRangeType={dateRangeType}
              dateArrayHeader={dateArrayHeader}
            />
          ))}
        </Box>
      </Box>

      {/* <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid

        rows={rows}
        columns={columns}
        pageSize={5}  // controls how many rows in the table
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
          }
      />
    </Box> */}

      <DataModal
        tableData={tableData}
        isOpen={moduleInView}
        onClose={() => setModuleInView(!moduleInView)}
      />
    </>
  );
};

export default DataTable;
