import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { BasicEmployeeInfo } from './types/employeeTypes';

const getBasicEmployee = async (storeUID: string): Promise<BasicEmployeeInfo[]> => {
  try {
    const storeEmployees = query(
      collection(db, 'employees'),
      where('storeID', '==', storeUID),
      where('status', '!=', 'terminated'),
    );
    const querySnapshotRock = await getDocs(storeEmployees);
    const allEmployees: BasicEmployeeInfo[] = [];
    // eslint-disable-next-line
    querySnapshotRock.forEach((doc: any) => {
      const { firstName, lastName } = doc.data();
      allEmployees.push({
        uid: doc.id,
        name: `${firstName} ${lastName}`,
      });
    });
    return allEmployees;
  } catch (error) {
    throw Error('Failed to fetch basic employee data');
  }
};

export { getBasicEmployee };
