import { Box, Button, Grid, Stack, useTheme } from '@mui/material';
import Home from './components/Home';
import s from './styles.module.scss';
// import DataTable from './components/DataTable';
import CreateModal from '../createModal/CreateModal';
import Rocks from './components/Rocks';
import Data from './components/Data';
import { Employee } from '../../../types/types';
import { useDashboardContext } from '../../../context/DashboardContext';

// xs: mobile, sm: mobile-tablets, md: tablets( 900px ) - 1024px, lg: laptop, xl: monitor
type Props = {
  user: Employee;
};

const Dashboard = ({ user }: Props) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const {
    moduleInView,
    page,
    activeButtonIndex,
    editItem,
    editItemType,
    setModuleInView,
    setTeamTodosList,
    setRockTodosList,
    dashboardButtons,
    handleEditItemClick,
    resetEditItem,
  } = useDashboardContext();

  return (
    <>
      <Box bgcolor={'#fff'}>
        <Stack direction={{ xs: 'column' }}>
          <Grid container justifyContent={'space-around'}>
            <Grid
              item
              my={1}
              xs={12}
              sm={5}
              ml={{ md: 1 }}
              justifyContent={{ display: 'flex', xs: 'center', sm: 'start', md: 'start' }}
            >
              <Button
                onClick={() => dashboardButtons('Home')}
                className={activeButtonIndex === 0 ? `${s.active}` : ''}
                sx={{ mx: 1 }}
                variant='outlined'
              >
                Home
              </Button>
              {/* <Button
                onClick={() => { dashboardButtons('Data') }}
                className={activeButtonIndex === 1 ? `${s.active}` : ''}
                sx={{ mx: 1 }}
                variant='outlined'
              >
                Data
              </Button> */}
              {/* <Button
                onClick={() => { dashboardButtons('Rocks'); setModalInitialIndex(1) }}
                className={activeButtonIndex === 2 ? `${s.active}` : ''}
                sx={{ mx: 1 }}
                variant='outlined'
              >
                Rocks
              </Button> */}
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              ml={{ md: 2 }}
              justifyContent={{ display: 'flex', xs: 'center', sm: 'end', md: 'end' }}
            >
              {/* NOTE this how to set padding and margin for multiple screen size. Also come back and look
          into REM units for reactive sizes */}
              <Button
                sx={{ px: { xs: 17, sm: 2 }, m: { xs: 2 }, color: 'white' }}
                variant='contained'
                onClick={() => setModuleInView(!moduleInView)}
              >
                Create
              </Button>
              `
            </Grid>
          </Grid>
          {/* SECTION Added Primary color bar. Like Power point */}
          <Grid container sx={{ display: { xs: 'none', md: 'block' }, my: { md: 1 } }}>
            <Grid width='100%' height='6vh' item xs={12} bgcolor={primaryColor}></Grid>
          </Grid>
          {/* SECTION Home Page component is injected here */}
          {page === 'Home' ? (
            <Home
              user={user}
              setTeamTodosList={setTeamTodosList}
              setRockTodosList={setRockTodosList}
              handleItemEdit={handleEditItemClick}
            />
          ) : page === 'Data' ? (
            // SECTION Data page would go here. Go Idea to make each page a component
            <Data />
          ) : (
            // SECTION Rock page would go here
            <Rocks
              user={user}
              setRockTodosList={setRockTodosList}
              handleItemEdit={handleEditItemClick}
            />
          )}
        </Stack>
      </Box>
      {moduleInView && (
        <CreateModal
          user={user}
          itemToEdit={editItem}
          editItemType={editItemType}
          closeDialog={() => {
            setModuleInView(false);
            resetEditItem();
          }}
          resetAfterSuccesfulEdit={resetEditItem}
          setIndex={0}
        />
      )}
    </>
  );
};

export default Dashboard;
