import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  writeBatch,
} from 'firebase/firestore';
import { Employee } from '../../types/types';
import { db } from '../../firebase-config';
import { FirebaseRock, OSRock } from './types/rockTypes';

/**
 * This function will fetch the Team and Personal Rocks for the user ID
 * being received as a parameter
 */
const getPersonalAndTeamRocks = async (user: Employee): Promise<OSRock[]> => {
  try {
    const allRocksQuery = query(
      collection(db, 'rocks'),
      where('storeUID', '==', user.storeID),
      where('isDeleted', '==', false),
    );
    const querySnapshotRock = await getDocs(allRocksQuery);
    const allRocks: OSRock[] = [];
    // eslint-disable-next-line
    querySnapshotRock.forEach((doc: any) => {
      allRocks.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return allRocks;
  } catch (error) {
    throw Error('Failed to fetch personal todos');
  }
};

const updateRockStatus = async (
  user: Employee,
  rock: OSRock,
  newStatus: string,
): Promise<boolean> => {
  try {
    const rockRef = doc(db, 'rocks', rock.id);
    await updateDoc(rockRef, {
      status: newStatus,
    });
    return true;
  } catch {
    // throw Error('Failed to fetch personal todos');
    return false;
  }
};

const createNewRock = async (newRock: FirebaseRock): Promise<OSRock> => {
  try {
    // eslint-disable-next-line
    const doc: any = await addDoc(collection(db, 'rocks'), newRock);
    const rock = {
      id: doc.id,
      ...newRock,
    };
    return rock;
  } catch (error) {
    throw Error('Failed to create new todo.');
  }
};
// function for creating a batch of Rocks
const createRockBatch = async (rocksData: FirebaseRock[]): Promise<OSRock[]> => {
  try {
    const batch = writeBatch(db);
    const newRocks: OSRock[] = [];
    rocksData.forEach((rd) => {
      // creating this doc will create the ID
      const newRockRef = doc(collection(db, 'rocks'));
      batch.set(newRockRef, rd);
      const rockWithId: OSRock = {
        id: newRockRef.id,
        ...rd,
      };
      newRocks.push(rockWithId);
    });
    return batch
      .commit()
      .then(() => {
        return newRocks;
      })
      .catch(() => {
        return [];
      });
  } catch (error) {
    return [];
  }
};

const deleteRock = async (rock: OSRock) => {
  try {
    const rockRef = doc(db, 'rocks', rock.id);
    await updateDoc(rockRef, {
      isDeleted: true,
    });
    return rock.id;
  } catch {
    // throw Error('Failed to fetch personal todos');
    return false;
  }
};

const editRock = async (rock: OSRock) => {
  try {
    const _rock = {
      comments: rock.comments,
      position: rock.position,
      archived: rock.archived,
      description: rock.description,
      owner: {
        name: rock.owner.name,
        uid: rock.owner.uid,
      },
      createdAt: rock.createdAt,
      team: {
        name: rock.team.name,
        uid: rock.team.uid,
      },
      status: rock.status,
      milestones: [...rock.milestones],
      title: rock.title,
      dueDate: rock.dueDate,
      isPersonal: rock.isPersonal,
      isDeleted: rock.isDeleted,
      storeUID: rock.storeUID,
      createdBy: {
        uid: rock.createdBy.uid,
        name: rock.createdBy.name,
      },
    };
    const rockRef = doc(db, 'rocks', rock.id);
    await updateDoc(rockRef, _rock);
    return rock;
  } catch (error) {
    return;
  }
};

export {
  createNewRock,
  createRockBatch,
  deleteRock,
  editRock,
  getPersonalAndTeamRocks,
  updateRockStatus,
};
