import { Avatar, Box, Divider, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { formateYesNo } from '../../../../../utils/formatting';
import IndividualCell from './IndividualCell';

type DataItem = {
  id: number;
  status: string;
  owner: string;
  title: string;
  goal: number | boolean;
  goalOrentationRate: string;
  goalOrentation: string;
  description: string;
  unit: string;
  currency: string;
  average: number;
  total: number;
  minValue: number | null;
  maxValue: number | null;
  rollingWeeks: {
    weekStart: Date | string;
    weekEnd: Date | string;
    value: number | boolean | string | null;
  }[];
};

type Props = {
  dataItem: DataItem;
  setDataCellClickedOn: Dispatch<SetStateAction<number>>;
  handleCellClicked: (rowIndex: number, columnIndex: number) => void;
  rowIndex: number;
  view: number[];
  setView: Dispatch<SetStateAction<number[]>>;
  handleBlur: () => void;
  startDate: string;
  endDate: string;
  setStartDate: Dispatch<SetStateAction<string>>;
  setEndDate: Dispatch<SetStateAction<string>>;
  dateRangeType: string;
  dateArrayHeader: string[];
};

const DataRow = ({
  dataItem,
  setDataCellClickedOn,
  handleCellClicked,
  rowIndex,
  view,
  handleBlur,
  startDate,
  setView,
  dateArrayHeader,
}: Props) => {
  const [dataItemValue, setDataItemValue] = useState<DataItem>(dataItem);
  const [goalEditMode, setGoalEditMode] = useState<string>('read');
  const [goalValue, setGoalValue] = useState<number | boolean | string>(dataItem.goal || 0);
  // const dateStrings = generateRollingWeeksHeaderArray(dateRangeType, startDate, endDate )

  // SUNDAY useEffect with Array fill = fill with random thing you want
  // useEffect(() => {
  //     // Update dataItemValue.rollingWeeks based on dateArrayHeader length

  //     const currentLength = dataItemValue.rollingWeeks.length;
  //     const newLength = dateArrayHeader.length;

  //     const currentLength = dataItemValue.rollingWeeks.length;
  //     const newLength = dateArrayHeader.length;

  //     if (newLength > currentLength) {
  //       // Increase the length of rollingWeeks by adding empty items
  //       const updatedRollingWeeks = [
  //         ...dataItemValue.rollingWeeks,
  //        ...Array(newLength - currentLength).fill('x')

  //       ];
  //       console.log(updatedRollingWeeks, 'Value before set for increase Columsns ');

  //       setDataItemValue(prevData => ({
  //         ...prevData,
  //         rollingWeeks: updatedRollingWeeks,
  //       }));
  //     } else if (newLength < currentLength) {
  //       // Decrease the length of rollingWeeks by slicing
  //       const updatedRollingWeeks = dataItemValue.rollingWeeks.slice(0, newLength);
  //       setDataItemValue(prevData => ({
  //         ...prevData,
  //         rollingWeeks: updatedRollingWeeks,
  //       }));
  //     }
  //   }, [dateArrayHeader]);

  // SUNDAY 14th attempt set dataItemValue using => setState
  // useEffect(() => {
  //   // Update dataItemValue.rollingWeeks based on dateArrayHeader length
  //   const currentLength = dataItemValue.rollingWeeks.length;
  //   const newLength = dateArrayHeader.length;

  //   if (newLength > currentLength) {
  //     // Increase the length of rollingWeeks by adding new instances
  //     const rollingWeeksToAdd = newLength - currentLength;
  //     const newRollingWeeks = Array.from({ length: rollingWeeksToAdd }, () => ({
  //       weekStart: new Date(),
  //       weekEnd: new Date(),
  //       value: 5
  //     }));

  //     setDataItemValue(prevData => ({
  //       ...prevData,
  //       rollingWeeks: [...prevData.rollingWeeks, ...newRollingWeeks],
  //     }));
  //   } else if (newLength < currentLength) {
  //     // Decrease the length of rollingWeeks by slicing
  //     setDataItemValue(prevData => ({
  //       ...prevData,
  //       rollingWeeks: prevData.rollingWeeks.slice(0, newLength),
  //     }));
  //   }
  // }, [dateArrayHeader]);

  // Tried Array.From
  // useEffect(() => {
  //   // Update dataItemValue.rollingWeeks based on dateArrayHeader length
  //   const currentLength = dataItemValue.rollingWeeks.length;
  //   const newLength = dateArrayHeader.length;

  //   if (newLength > currentLength) {
  //     // Increase the length of rollingWeeks by adding new instances
  //     const rollingWeeksToAdd = newLength - currentLength;
  //     const newRollingWeeks: RollingWeek[] = [];

  //     Array.from({ length: rollingWeeksToAdd }).forEach(() => {
  //       newRollingWeeks.push({
  //         weekStart: new Date(),
  //         weekEnd: new Date(),
  //         value: 5
  //       });
  //     });

  //     setDataItemValue(prevData => ({
  //       ...prevData,
  //       // rollingWeeks: [...prevData.rollingWeeks, ...newRollingWeeks],
  //        rollingWeeks: [...prevData.rollingWeeks, ...newRollingWeeks.map(obj => ({ ...obj }))]
  //     }));
  //     console.log(dataItemValue.rollingWeeks, 'dataItemValue.rollingWeeks after being set');

  //   } else if (newLength < currentLength) {
  //     // Decrease the length of rollingWeeks by slicing
  //     setDataItemValue(prevData => ({
  //       ...prevData,
  //       rollingWeeks: prevData.rollingWeeks.slice(0, newLength),
  //     }));
  //   }
  // }, [dateArrayHeader]);

  // MONDAY 15th attemp with a push depending length difference or Add or Subtract
  useEffect(() => {
    const currentLength = dataItemValue.rollingWeeks.length;
    const newLength = dateArrayHeader.length;
    if (currentLength !== newLength) {
      // const updatedRollingWeeks = [rollingWeek]
      const _dataItemValue = { ...dataItemValue };

      // Assuming _dataItemValue is an object with a property named rollingWeeks

      if (newLength > currentLength) {
        // Push new rollingWeeks to match the newLength
        const numberOfWeeksToAdd = newLength - currentLength;
        console.log(numberOfWeeksToAdd, 'num of weeks to add');

        for (let i = 0; i < numberOfWeeksToAdd; i++) {
          const newRollingWeek = {
            weekStart: new Date(),
            weekEnd: new Date(),
            value: 7,
          };
          _dataItemValue.rollingWeeks.push(newRollingWeek);
        }
      } else {
        // Remove rollingWeeks to match the newLength
        const numberOfWeeksToRemove = currentLength - newLength;
        _dataItemValue.rollingWeeks.splice(-numberOfWeeksToRemove);
      }

      setDataItemValue(_dataItemValue);
    }
  }, [dateArrayHeader]);

  // This UseEffect is for updating total and averages for numbers to the left of the dataTable
  // NOTE rollingWeeksLengthCount will even give you the right number on change but just display in my map below
  useEffect(() => {
    let newTotal = 0;
    let newAverage = 0;
    let rollingWeeksLengthCount = 0;

    // NOTE / TODO Show Miguel useEffect. I think this can be simplified and there is still some problems
    dataItemValue.rollingWeeks.forEach(
      (week) => {
        if (dataItemValue.unit === 'Yes/No') {
          if (
            (week.value === true && dataItemValue.goal == true) ||
            (week.value === false && dataItemValue.goal === false)
          ) {
            newTotal++;
            // console.log(week.value, [newTotal], 'If Value Matches Goal Increase');
          } else if (week.value != dataItemValue.goal) {
            // console.log(week.value, [newTotal], 'This should be for Values opposite of goals');
          }
          rollingWeeksLengthCount++; // Increment count for 'Yes/No' values
          // console.log(rollingWeeksLengthCount, 'Rolling weeks updated on total / Other useEffect but numbers add up correctly');
        } else if (typeof week.value === 'number' && !isNaN(week.value)) {
          newTotal += week.value;
          rollingWeeksLengthCount++; // Increment count for numeric values
        }
      },
      [dateArrayHeader],
    );

    // Create a new object to avoid directly modifying state variables
    // const updatedDataItemValue: any = {
    //   ...dataItemValue,
    //   total: newTotal,
    //   average: newAverage,
    // };

    // NOTE / TODO Show Miguel useEffect. I think this can be simplified and there is still some problems
    dataItemValue.rollingWeeks.forEach((week) => {
      if (dataItemValue.unit === 'Yes/No') {
        if (
          (week.value === true && dataItemValue.goal == true) ||
          (week.value === false && dataItemValue.goal === false)
        ) {
          newTotal++;
          // console.log(week.value, [newTotal], 'If Value Matches Goal Increase');
        } else if (week.value != dataItemValue.goal) {
          // console.log(week.value, [newTotal], 'This should be for Values opposite of goals');
        }
        rollingWeeksLengthCount++; // Increment count for 'Yes/No' values
      } else if (typeof week.value === 'number' && !isNaN(week.value)) {
        newTotal += week.value;
        rollingWeeksLengthCount++; // Increment count for numeric values
      }
    });

    // Calculate the new average only if there are numeric values
    if (rollingWeeksLengthCount > 0) {
      newAverage = newTotal / rollingWeeksLengthCount;
    }

    // Create a new object to avoid directly modifying state variables
    const updatedDataItemValue = {
      ...dataItemValue,
      total: newTotal,
      average: newAverage,
    };

    // Update state only if the new data differs from the current state
    if (
      updatedDataItemValue.total !== dataItemValue.total ||
      updatedDataItemValue.average !== dataItemValue.average
    ) {
      setDataItemValue(updatedDataItemValue);
    }
  }, [dataItemValue]);

  const handleGoalChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (!isNaN(parseInt(newValue)) || newValue === '') {
      setGoalValue(newValue);
      const _dataCellValue = { ...dataItemValue };
      _dataCellValue.goal = newValue === '' ? 0 : parseInt(newValue);
      // updateDataTableOffDataCells(_dataCellValue);
      setDataItemValue(_dataCellValue);
      console.log(_dataCellValue, 'new value');
    }
    // TODO boolean logic will go here
  };

  // NOTE this useEffect controls the Dates for Filtering Date Ranges
  // useEffect(() => {
  //   if (startDate && endDate) {
  //     const _dataCellValue = { ...dataItemValue };

  //     _dataCellValue.rollingWeeks = _dataCellValue.rollingWeeks.filter(
  //       (week) =>
  //         (new Date(week.weekStart) >= new Date(startDate) &&
  //           new Date(week.weekStart) <= new Date(endDate)) ||
  //         (new Date(week.weekEnd) >= new Date(startDate) &&
  //           new Date(week.weekEnd) <= new Date(endDate)),
  //     );

  //     setDataItemValue(_dataCellValue);
  //   }
  // }, [startDate, endDate, generateRollingWeeksHeaderArray]);

  // NOTE pass Min and Max Num for inside and out min and max
  const formatGoalOrentationRate = (
    goalOrentationRate: string,
    minValue: number | null,
    maxValue: number | null,
  ) => {
    if (goalOrentationRate == 'Greater than Goal') {
      return '>';
    } else if (goalOrentationRate == 'Greater than or equal to Goal') {
      return '>=';
    } else if (goalOrentationRate == 'Equal to Goal') {
      return '=';
    } else if (goalOrentationRate == 'Less than or equal to Goal') {
      return '<=';
    } else if (goalOrentationRate == 'Less than Goal') {
      return '<';
    } else if (goalOrentationRate == 'Inside min and max') {
      // min >< max
      return `>=${minValue} and <=${maxValue}`;
    } else {
      // min <> max
      // TODO come back and change these symbols need to add form fields for min and max value and may need to pass them
      return ` <${minValue} or >${maxValue}`;
    }

    // TODO 'Inside min and max', 'Outside min and max', 'Less equal, Less than
  };

  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      divider={<Divider orientation='vertical' flexItem />}
      spacing={2}
      sx={{ borderBottom: 1, borderColor: 'divider', width: 'fit-content' }}
    >
      <Divider orientation='vertical' flexItem />
      <Box p={1.2}>
        {dataItemValue.status === 'On-Track' ? (
          <Box width={'5px'} height={'5vh'} bgcolor={'green'} mt={1}></Box>
        ) : dataItemValue.status === 'Off-Track' ? (
          <Box width={'5px'} height={'5vh'} bgcolor={'red'} mt={1}></Box>
        ) : (
          <Box width={'5px'} height={'5vh'} bgcolor={'yellow'} mt={1}></Box>
        )}
      </Box>
      <Box>
        <Avatar alt='Remy Sharp' src={dataItemValue.owner} />
      </Box>
      <Box maxWidth={170} minWidth={170} sx={{ overflowX: 'hidden' }}>
        <Typography>{dataItemValue.title}</Typography>
      </Box>
      <Box display={'flex'} maxWidth={50} minWidth={50} sx={{ overflowX: 'hidden' }}>
        {goalEditMode === 'read' ? (
          <>
            {dataItemValue.unit != 'Yes/No' ? (
              <Typography letterSpacing={2}>
                {formatGoalOrentationRate(
                  dataItemValue.goalOrentationRate,
                  dataItemValue.minValue,
                  dataItemValue.maxValue,
                )}
              </Typography>
            ) : (
              ''
            )}
            {dataItemValue.unit == 'Currency' ? (
              <Typography>{dataItemValue.currency}</Typography>
            ) : (
              ''
            )}
            <Typography>{formateYesNo(dataItemValue.goal)}</Typography>
            {dataItemValue.unit == 'Percentage' ? <Typography>%</Typography> : ''}
          </>
        ) : (
          <TextField onBlur={handleBlur} value={goalValue} onChange={handleGoalChange} />
        )}
      </Box>
      <Box maxWidth={50} minWidth={50} sx={{ overflowX: 'hidden' }}>
        {+dataItemValue.goal >= dataItemValue.average ? (
          <Typography color={'red'}>{dataItemValue.average.toFixed(2)}</Typography>
        ) : (
          <Typography color={'green'}>{dataItemValue.average.toFixed(2)}</Typography>
        )}
      </Box>
      <Box maxWidth={50} minWidth={50} sx={{ overflowX: 'hidden' }}>
        {dataItemValue.unit == 'Percentage' ? (
          <Typography>-</Typography>
        ) : (
          <Typography>{dataItemValue.total}</Typography>
        )}
      </Box>
      {/* SECTION this is where Date data starts */}
      {dataItemValue.rollingWeeks.map((data, columnIndex) => {
        if (data.weekStart > new Date()) {
          return (
            <Box key={columnIndex} onClick={() => handleCellClicked(rowIndex, columnIndex)}>
              {/* PASS Prop inFocus fuction  If this cell is click passed the Prop if not it is clicked */}
              <IndividualCell
                view={view[0] == rowIndex && view[1] == columnIndex ? 'write' : 'read'}
                startDate={startDate}
                singleDataValue={dataItem}
                handleBlur={handleBlur}
                updateDataTableOffDataCells={setDataItemValue}
                rollingWeekIndex={columnIndex}
                setDataCellClickedOn={setDataCellClickedOn}
                setView={setView}
              />
            </Box>
          );
        }
      })}
      <Divider orientation='vertical' flexItem />
    </Stack>
  );
};

export default DataRow;
