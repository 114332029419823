import { Edit } from '@mui/icons-material';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';

type Props = {
  // moveToNextStep: () => void;
  handleSaveClick: (wetWeight: number) => void;
};

const FloatTextField = ({ handleSaveClick }: Props) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [weightValue, setWeightValue] = useState<string>('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue === '' || /^-?\d*\.?\d{0,2}$/.test(inputValue)) {
      setWeightValue(inputValue);
    }
  };

  return (
    <Box
      display={'flex'}
      flexDirection={{ xs: 'column' }}
      width={'100%'}
      alignItems={'center'}
      gap={2}
    >
      <Typography fontWeight={'bold'}>Wet Weight</Typography>
      {currentStep == 0 ? (
        <TextField
          value={weightValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
          label={'Wet Weight'}
          InputProps={{
            endAdornment: <InputAdornment position='end'>lbs</InputAdornment>,
          }}
        />
      ) : (
        <Box display={'flex'} width={'100%'} height={'4vh'}>
          <Typography textAlign={'right'} fontWeight={'bold'} width={'50%'} fontSize={18}>
            {weightValue}
          </Typography>
          <Button onClick={() => setCurrentStep(0)}>
            <Edit />
          </Button>
        </Box>
      )}
      {currentStep == 0 ? (
        <Button variant='contained' onClick={() => setCurrentStep(1)}>
          Add Weight
        </Button>
      ) : (
        <Button variant='contained' onClick={() => handleSaveClick(parseFloat(weightValue))}>
          Submit
        </Button>
      )}
    </Box>
  );
};

export default FloatTextField;
