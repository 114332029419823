import { Box, Card, Chip, Grid, IconButton, Stack, Typography, useTheme } from '@mui/material';
import StatusMenu from '../../StatusMenu';
import EditIcon from '@mui/icons-material/Edit';
import { formatDateMonthDayYear } from '../../../../../../utils/formatting';
import { Employee, Todo } from '../../../../../../types/types';
import DeleteIcon from '@mui/icons-material/Delete';
import { RockItem, TodoItem } from '../../../../types';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ModalTypes } from '../../../../createModal/CreateModal.js';
import { OSRock, RockStatus } from '../../../../../../api/fc-os/types/rockTypes';

type Props = {
  user: Employee;
  rockTodo: OSRock;
  deleteRock: (rockTodo: OSRock) => void;
  onStatusChange: (id: string, newStatus: RockStatus) => void;
  handleEditClick: (item: TodoItem | OSRock, type: ModalTypes) => void;
};

const PersonalRockRow = ({
  user,
  rockTodo,
  onStatusChange,
  deleteRock,
  handleEditClick,
}: Props) => {
  const theme = useTheme();
  const lightGray = theme.palette.primary.light;
  const mainBlue = theme.palette.primary.main;

  const deleteRockItem = (rockTodo: OSRock) => {
    deleteRock(rockTodo);
  };
  return (
    <Card
      key={rockTodo.id}
      sx={{
        py: 1,
        px: 1,
        cursor: 'pointer',
        '&:hover': { backgroundColor: lightGray, '.drag-icon': { opacity: 1 } },
      }}
    >
      <Grid container alignItems={'center'}>
        <Grid item xs={2}>
          <Stack direction={'row'}>
            <Box className='drag-icon' sx={{ opacity: 0 }}>
              <DragIndicatorIcon htmlColor={theme.palette.primary.dark} />
            </Box>
            <StatusMenu rock={rockTodo} onStatusChange={onStatusChange} />
          </Stack>
        </Grid>
        <Grid item xs={7} alignItems={'center'} display={'flex'}>
          {!rockTodo.isPersonal && (
            <Chip label='Company' style={{ backgroundColor: mainBlue, color: '#fff' }} />
          )}
          <Typography ml={1}>{rockTodo.title}</Typography>
          <IconButton onClick={() => handleEditClick(rockTodo, 'Rock')}>
            <EditIcon
              htmlColor={lightGray}
              sx={{
                opacity: 0,
                '&:hover': {
                  opacity: 1,
                },
              }}
            ></EditIcon>
          </IconButton>
        </Grid>
        <Grid item xs={2} display={'flex'} justifyContent={'center'}>
          <Typography textAlign={'center'}>
            {formatDateMonthDayYear(rockTodo.dueDate.toDate().toString())}
          </Typography>
        </Grid>
        <Grid item xs={1} display={'flex'} justifyContent={'end'}>
          <IconButton onClick={() => deleteRockItem(rockTodo)}>
            <DeleteIcon htmlColor='red'></DeleteIcon>
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PersonalRockRow;
