import { Box, Button, Typography } from '@mui/material';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

type Props = {
  userData: { name: string; address: string; notes: string; trackDryWetWeight: boolean };
  wetWeight: number;
  qrCode: string;
};

const WetWeightTag = ({ userData, wetWeight, qrCode }: Props) => {
  const printComponentRef = useRef(null);
  const handlePrintReceipt = useReactToPrint({
    content: () => printComponentRef.current,
  });

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Typography textAlign={'center'} fontWeight={'bold'}>
          <span style={{ fontWeight: 'normal', marginRight: 8 }}>Wet Weight:</span>
          {wetWeight} lbs
        </Typography>
        <Button variant='contained' onClick={handlePrintReceipt}>
          Print Tag
        </Button>
      </Box>
      {/* USED FOR PRINTING SERVICES */}
      <Box display={'none'} width={'100%'}>
        <Box
          display={'flex'}
          sx={{ alignItems: 'center', justifyContent: 'center' }}
          width={'100%'}
          height={800}
          ref={printComponentRef}
        >
          <Box width={260}>
            <Typography
              borderBottom={'1px solid #000'}
              paddingBottom={1}
              paddingTop={1}
              paddingLeft={1}
              fontSize={16}
              fontWeight={'bold'}
              textAlign={'center'}
            >
              {userData.name}
            </Typography>
            <Typography
              borderBottom={'1px solid #000'}
              paddingBottom={1}
              paddingTop={1}
              paddingLeft={1}
              fontSize={16}
              textAlign={'center'}
            >
              Wet Weight: {wetWeight}
            </Typography>
            {userData.notes && (
              <Typography
                paddingBottom={1}
                paddingTop={1}
                paddingLeft={1}
                fontSize={16}
                borderBottom={'1px solid #000'}
              >
                <b>Notes: </b>
                {userData.notes}
              </Typography>
            )}
            {qrCode && qrCode?.length > 0 && (
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                borderTop={'1px solid #000'}
              >
                <Typography textAlign={'center'} padding={1}>
                  Scan QR code to add the dry weight
                </Typography>
                <Box padding={1}>
                  <img src={qrCode} alt='qr code receipt' />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default WetWeightTag;
