import { Box, Button, Card, TextField, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { resetEmployeePassword } from '../../api/employee';
import { validateRegex } from '../../utils/validating';
import toast from 'react-hot-toast';

type Values = {
  email: string;
};

const ForgotPassword = () => {
  const [emailSending, setEmailSending] = useState<boolean>(false);
  const [values, setValues] = useState<Values>({
    email: '',
  });

  const handleValueChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleResetButtonClick = () => {
    setEmailSending(true);
    const validEmail = validateRegex(
      values.email,
      new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'),
    );
    if (!validEmail) {
      setEmailSending(false);
      return;
    }
    resetEmployeePassword(values.email);
    toast.success(`Reset email sent to: ${values.email}.`);
  };

  return (
    <Box display='flex' justifyContent='center' alignItems='center' height='100vh'>
      <Card sx={{ width: '70%', maxWidth: '600px' }}>
        <Link to={'/login'} color={'#000'} style={{ textDecoration: 'none' }}>
          <Box
            width={{ xs: '50vw', md: '20vw', lg: '12vw' }}
            height={'5vh'}
            borderRadius={8}
            border={'2px solid #e3e3e3'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <ArrowLeftIcon fontSize='small' htmlColor='#000' />{' '}
            <Typography color={'#000'} fontSize={11} fontWeight={'bold'}>
              Back to login
            </Typography>
          </Box>
        </Link>
        <img src='/logo.png' alt='Fresh Clothes Logo' style={{ height: 200, width: 200 }} />
        <TextField
          label='Email'
          margin='dense'
          fullWidth
          value={values.email}
          name='email'
          onChange={(e) => handleValueChange(e)}
        />
        <Button
          variant='contained'
          size='large'
          sx={{ color: '#fff', fontWeight: 'bold', width: '100%', mt: 2, mb: 2 }}
          onClick={handleResetButtonClick}
          disabled={emailSending}
        >
          Reset password
        </Button>
      </Card>
    </Box>
  );
};

export default ForgotPassword;
