import { Box, Grid, Hidden, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useRef } from 'react';
import { Employee, Todo } from '../../../../../types/types';
import CompanyRockRow from './component rows/CompanyRockRow';
import { RockItem, TodoItem } from '../../../types';
import { ModalTypes } from '../../../createModal/CreateModal.js';
import { RockStatus, OSRock } from '../../../../../api/fc-os/types/rockTypes';

type Props = {
  user: Employee;
  rockTodos: OSRock[];
  setRockTodosList: Dispatch<SetStateAction<OSRock[]>>;
  handleItemEdit: (item: TodoItem | OSRock, type: ModalTypes) => void;
};

const CompanyRocks = ({ user, rockTodos, setRockTodosList, handleItemEdit }: Props) => {
  // const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);

  // const handleThumbClose = () => {
  //   setAnchorEl(null);
  // };

  const rockStatusChange = (id: string, newStatus: RockStatus): void => {
    const _rockTodosList = [...rockTodos];
    const todoIndex = _rockTodosList.findIndex((todo) => todo.id == id);

    if (todoIndex > -1) {
      const _rockTodo = { ..._rockTodosList[todoIndex] };
      _rockTodo.status = newStatus;
      console.log({ _rockTodo });

      _rockTodosList[todoIndex] = _rockTodo;
      setRockTodosList(_rockTodosList);
    }
    // handleThumbClose();
  };

  const deleteRock = (rockTodoItem: OSRock) => {
    if (window.confirm('Are you sure?')) {
      const _rockTodosCopy = [...rockTodos];
      const rockTodoIndex = _rockTodosCopy.findIndex((rockTodo) => rockTodo.id == rockTodoItem.id);
      if (rockTodoIndex !== -1) {
        _rockTodosCopy.splice(rockTodoIndex, 1); // Remove 1 element at teamTodoIndex
        setRockTodosList(_rockTodosCopy);
      }
    }
  };

  // DRAGGABLE FUNCTIONS
  // saving references for dragItem and dragOverItem
  // eslint-disable-next-line
  const dragItem = useRef<any>(null);
  // eslint-disable-next-line
  const dragOverItem = useRef<any>(null);
  // handling sorting drag & drop
  const handleSort = () => {
    // duplicate items
    const _itemsSelected = [...rockTodos];
    // remove and save the dragged item content
    const draggedItemContent = _itemsSelected.splice(dragItem.current, 1)[0];
    // switch the position
    _itemsSelected.splice(dragOverItem.current, 0, draggedItemContent);
    // reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;
    // update the actual array
    setRockTodosList(_itemsSelected);
  };

  return (
    <Box my={4} p={1} sx={{ flexGrow: 1, boxShadow: 5 }}>
      <Typography gutterBottom variant='h6'>
        Company Rocks
      </Typography>

      {/* SECTION Grid */}
      <Box py={0}>
        <Grid container>
          <Grid item xs={2}>
            <Typography variant='subtitle2' color={'gray'} pl={4}>
              Status
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant='subtitle2' color={'gray'}>
              Title
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography ml={5} variant='subtitle2' color={'gray'}>
              Due By
            </Typography>
          </Grid>
          <Hidden mdDown>
            <Grid item xs={1}>
              <Typography variant='subtitle2' color={'gray'}>
                Owner
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
      </Box>
      {/* SECTION V-for / Map */}
      {rockTodos.map((rockTodo, index) => {
        return (
          <Box
            key={`rock-${index}`}
            draggable
            onDragStart={() => (dragItem.current = index)}
            onDragEnter={() => (dragOverItem.current = index)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}
          >
            <CompanyRockRow
              key={rockTodo.id}
              rockTodo={rockTodo}
              deleteRock={deleteRock}
              onStatusChange={rockStatusChange}
              handleEditClick={handleItemEdit}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default CompanyRocks;
