import BookmarkIcon from '@mui/icons-material/Bookmark';
import { Box, Button, List, ListItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import QRCode from 'qrcode';
import { ChangeEvent, useEffect, useState } from 'react';
import s from '../../styles.module.scss';
import PrintableTag from './PrintableTag';
import { ReceiptItem } from './types';

type Props = {
  receiptUID: string;
  itemsList: ReceiptItem[];
  userData: { name: string; address: string; notes: string; trackDryWetWeight: boolean };
};

const TagsItemSelect = ({ receiptUID, itemsList, userData }: Props) => {
  const [qrCode, setQrCode] = useState<{ binNumber: string; url: string | null }>({
    binNumber: receiptUID,
    url: null,
  });
  const [printableTagModalInView, setPrintableTagModalInView] = useState<boolean>(false);
  // the items for the current tag being created
  const [tagsList, setTagsList] = useState<ReceiptItem[] | null>(null);
  // knowing which items are currently selected
  const tempSelectedArray = itemsList.map(() => {
    return false;
  });
  const [currentlySelectedItems, setCurrentlySelectedItems] =
    useState<boolean[]>(tempSelectedArray);
  // handling adding items. it will save the name of the item
  const [itemsInTags, setItemsInTags] = useState<string[]>([]);

  // generating the QR code that will be used for the tags
  const createQRCode = () => {
    let qrCodeURL = null;
    QRCode.toDataURL(
      `https://admin.freshclothes.app/#/receipts/${receiptUID}`,
      {
        margin: 1,
      },
      (err, qr) => {
        if (err) return console.error(err);
        qrCodeURL = qr;
      },
    );
    if (qrCodeURL) {
      setQrCode({ binNumber: receiptUID, url: qrCodeURL });
    }
  };

  const handleCheckboxClick = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const isChecked = event.target.checked;

    const _selectedItems = [...currentlySelectedItems];
    _selectedItems[index] = isChecked;
    // for updating the checkbox of the item
    setCurrentlySelectedItems(_selectedItems);

    if (isChecked) {
      const _tagsList = tagsList ? [...tagsList] : [];
      _tagsList.push(itemsList[index]);
      setTagsList(_tagsList);
    }
    // we need to see if we need to remove the item
    else {
      const elemToFind: ReceiptItem = itemsList[index];
      const foundIndex = tagsList?.findIndex((elem: ReceiptItem) => {
        return elem.item.name == elemToFind.item.name;
      });

      if (foundIndex !== undefined && foundIndex >= 0) {
        const _tagsListCopy = tagsList ? [...tagsList] : [];

        _tagsListCopy.splice(foundIndex, 1);
        setTagsList(_tagsListCopy);
      }
    }
  };

  const handleCreateTag = () => {
    setPrintableTagModalInView(true);
  };

  const resetTagCreation = () => {
    // code for tagging the items that already belong to
    // a tag
    const _taggedItems = [...itemsInTags];
    if (tagsList) {
      for (let i = 0; i < tagsList.length; i++) {
        if (!_taggedItems.includes(tagsList[i].item.name)) {
          _taggedItems.push(tagsList[i].item.name);
        }
      }
      setItemsInTags(_taggedItems);
      setTagsList(null);
      const _resetTaggedList = currentlySelectedItems.fill(false);
      setCurrentlySelectedItems(_resetTaggedList);
    }
  };

  const handleTagPrintableDialogClose = () => {
    resetTagCreation();
    setPrintableTagModalInView(false);
  };

  useEffect(() => {
    if (userData.trackDryWetWeight) {
      createQRCode();
    }
  }, []);

  return (
    <Box>
      <p style={{ textAlign: 'center', fontWeight: 'bold' }}>
        Tag Creation. Please select the items that will belong to a tag.
      </p>
      <List>
        {itemsList.map((receiptItem: ReceiptItem, index: number) => {
          return (
            <ListItem key={receiptItem.item.name}>
              <Box className={s.itemTagWrp}>
                <p className={s.itemTagName}>{receiptItem.item.name}</p>
                <p className={s.itemTagQuantity}>
                  {receiptItem.quantity} {receiptItem.item.quantitativeLabel}
                </p>
                {itemsInTags.includes(receiptItem.item.name) ? (
                  <BookmarkIcon fontSize='small' color={'primary'} />
                ) : null}
                <Checkbox
                  onChange={(e) => handleCheckboxClick(e, index)}
                  checked={currentlySelectedItems[index]}
                  style={{ gridColumn: '4/5' }}
                />
              </Box>
            </ListItem>
          );
        })}
      </List>
      {/* List of the items that are being added to the tag */}
      {tagsList && tagsList.length > 0 ? (
        <>
          <Box className={s.tagsList}>
            <p>Items for this Tag</p>
            <List>
              {tagsList.map((tag: ReceiptItem, index: number) => {
                return <ListItem key={`tag-${index}`}>{tag.item.name}</ListItem>;
              })}
            </List>
          </Box>
          {tagsList.length > 0 ? (
            <Button onClick={handleCreateTag} fullWidth>
              Create Tag
            </Button>
          ) : null}
          {/* List of the items that are being added to the tag */}
        </>
      ) : null}
      {printableTagModalInView && tagsList && (
        <PrintableTag
          data={tagsList}
          title={userData.name}
          subtitle={userData.address}
          notes={userData.notes}
          qrCode={qrCode.url ? qrCode.url : ''}
          handleClose={handleTagPrintableDialogClose}
        />
      )}
    </Box>
  );
};

export default TagsItemSelect;
