import {
  addDoc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import { Employee } from '../../types/types';
import { db } from '../../firebase-config';
import { OSTodo, FirebaseTodo } from './types/todos-types';

/**
 * This function will fetch the Team and Personal Todos for the user ID
 * being received as a parameter
 */
const getPersonalAndTeamTodos = async (user: Employee): Promise<OSTodo[]> => {
  // the query will filter by:
  // storeUID === user store
  // todo.personal === true && owners contains user id
  try {
    const personalTodosQuery = query(
      collection(db, 'todos'),
      where('personal', '==', true),
      where('isDeleted', '==', false),
      where('owner.uid', '==', user.uid),
    );
    const querySnapshotPersonal = await getDocs(personalTodosQuery);
    const teamTodosQuery = query(
      collection(db, 'todos'),
      where('personal', '==', false),
      where('isDeleted', '==', false),
    );
    const querySnapshotTeam = await getDocs(teamTodosQuery);
    const allQuerySnapshotTodos = querySnapshotPersonal.docs.concat(querySnapshotTeam.docs);
    const allTodos: OSTodo[] = [];
    // eslint-disable-next-line
    allQuerySnapshotTodos.forEach((doc: any) => {
      allTodos.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return allTodos;
  } catch (error) {
    throw Error('Failed to fetch personal todos');
  }
};

const createNewTodo = async (todoData: FirebaseTodo): Promise<OSTodo> => {
  try {
    // eslint-disable-next-line
    const doc: any = await addDoc(collection(db, 'todos'), todoData);
    const newTodo = {
      id: doc.id,
      ...todoData,
    };
    return newTodo;
  } catch (error) {
    throw Error('Failed to create new todo.');
  }
};
// function for creating a batch of Todos
const createTodoBatch = async (todosData: FirebaseTodo[]): Promise<OSTodo[]> => {
  try {
    const batch = writeBatch(db);
    const newTodos: OSTodo[] = [];
    todosData.forEach((td) => {
      // creating this doc will create the ID
      const newBinHistoryRef = doc(collection(db, 'todos'));
      batch.set(newBinHistoryRef, td);
      const todoWithId: OSTodo = {
        id: newBinHistoryRef.id,
        ...td,
      };
      newTodos.push(todoWithId);
    });
    return batch
      .commit()
      .then(() => {
        return newTodos;
      })
      .catch(() => {
        return [];
      });
  } catch (error) {
    return [];
  }
};

// this will update the value for isDeleted
const deleteTodoAPI = async (todoId: string) => {
  try {
    const todoRef = doc(db, 'todos', todoId);
    await updateDoc(todoRef, { isDeleted: true });
    return todoId;
  } catch (error) {
    throw Error('Failed to delete todo.');
  }
};

const editTodo = async (todo: OSTodo) => {
  try {
    const _removedIDTodo = {
      comments: todo.comments,
      createdAt: todo.createdAt,
      createdBy: todo.createdBy,
      description: todo.description,
      dueDate: todo.dueDate,
      isDeleted: todo.isDeleted,
      owner: todo.owner,
      personal: todo.personal,
      status: todo.status,
      storeUID: todo.storeUID,
      team: todo.team,
      title: todo.title,
      updateHistory: todo.updateHistory,
      updatedAt: Timestamp.now(),
    };
    const todoRef = doc(db, 'todos', todo.id);
    await updateDoc(todoRef, _removedIDTodo);
    return todo;
  } catch (error) {
    throw Error('Failed to delete todo.');
  }
};

const updateTodoStatus = async (changeData: { todoId: string; newStatus: string }) => {
  const { todoId, newStatus } = changeData;
  try {
    const todoRef = doc(db, 'todos', todoId);
    await updateDoc(todoRef, { status: newStatus });
    return true;
  } catch (error) {
    throw Error('Failed to change status');
  }
};

export {
  createNewTodo,
  createTodoBatch,
  editTodo,
  deleteTodoAPI,
  getPersonalAndTeamTodos,
  updateTodoStatus,
};
