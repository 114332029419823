import { Dialog, DialogContent } from '@mui/material';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { Employee, Receipt, User } from '../../../../types/types';
import { useAuth } from '../../../../context/AuthContext';
import QRModalDialogTitle from '../common/QRModalDialogTitle';
import ConfirmDialog from '../../../common/ConfirmDialog';
import FloatTextField from './FloatTextField';
import LoadingSpinner from '../../../common/LoadingSpinner';
import { Timestamp } from 'firebase/firestore';
import { createReceipt } from '../../../../api/receipts';
import toast from 'react-hot-toast';
import WetWeightTag from './WetWeightTag';
import QRCode from 'qrcode';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  user: User;
};

const WetWeightCorporateReceipt = ({ user, isOpen, setIsOpen }: Props) => {
  const { user: loggedInUser }: { user: Employee } = useAuth();
  const [confirmDialogInView, setConfirmDialogInView] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const finalWetWeight = useRef<number>(0);
  const [qrCode, setQrCode] = useState<{ binNumber: string; url: string | null }>({
    binNumber: '',
    url: null,
  });
  // a confirmation dialog box will appear before close the receipt modal
  const handleClose = (response: boolean) => {
    if (response) {
      setIsOpen(false);
    }
    setConfirmDialogInView(false);
  };

  const handleSave = async (wetWeight: number) => {
    setCurrentStep(1);
    // we need to
    const todaysDate = Timestamp.now();
    const newReceiptData: Receipt = {
      billTo: {
        address: user.address,
        city: user.city,
        fullName: user.fullName,
        postalcode: user.postalcode,
        state: user.state,
        email: user.email,
      },
      createdAt: todaysDate,
      createdBy: {
        fullName: `${loggedInUser.firstName} ${loggedInUser.lastName}`,
        email: loggedInUser.email,
        storeUID: loggedInUser.storeID ? loggedInUser.storeID : '',
        uid: loggedInUser.uid ? loggedInUser.uid : '',
      },
      dueDate: '',
      invoiceDate: '',
      invoiceNumber: 0,
      itemsList: [],
      finalized: false,
      leasingItems: user.leasingItems ? true : false,
      salesTax: 0,
      subtotal: 0,
      termsAndConditions: [],
      totalPrice: 0,
      totals: {
        weight: {
          dryTotal: 0,
          label: 'lbs',
          total: wetWeight,
        },
      },
      updatedAt: todaysDate,
      userUID: user.docID,
      type: user.receiptPickupType ? user.receiptPickupType : 'itemized',
    };

    const success = await createReceipt(newReceiptData);
    if (success) {
      createQRCode(success);
      finalWetWeight.current = wetWeight;
      setCurrentStep(2);
    } else {
      toast.error('Failed to save receipt. Please try again.');
    }
  };

  const createQRCode = (receiptUID: string) => {
    let qrCodeURL = null;
    QRCode.toDataURL(
      `https://admin.freshclothes.app/#/receipts/${receiptUID}`,
      // `http://localhost:3000/#/receipts/${receiptUID}`,
      {
        margin: 1,
      },
      (err, qr) => {
        if (err) return console.error(err);
        qrCodeURL = qr;
      },
    );
    if (qrCodeURL) {
      setQrCode({ binNumber: receiptUID, url: qrCodeURL });
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => setConfirmDialogInView(true)} fullWidth>
        <QRModalDialogTitle
          fullname={user.fullName}
          address={user.address}
          apt={user.apt ? user.apt : null}
          city={user.city}
          state={user.state}
          postalcode={user.postalcode}
        />
        <DialogContent>
          {currentStep === 0 ? (
            <FloatTextField handleSaveClick={handleSave} />
          ) : currentStep == 1 ? (
            <LoadingSpinner />
          ) : currentStep == 2 && qrCode.url ? (
            <WetWeightTag
              userData={{
                name: user.fullName,
                address: `${user.address} ${user.apt} ${user.city} ${user.state} ${user.postalcode}`,
                notes: user.notes ? user.notes : '',
                trackDryWetWeight: user.trackWetDryWeight ? user.trackWetDryWeight : false,
              }}
              wetWeight={finalWetWeight.current}
              qrCode={qrCode.url}
            />
          ) : null}
        </DialogContent>
      </Dialog>
      {confirmDialogInView && (
        <ConfirmDialog
          title={'Are you sure you would like to close the receipt creation?'}
          message={'Data will be lost'}
          handleClick={handleClose}
        />
      )}
    </>
  );
};

export default WetWeightCorporateReceipt;
