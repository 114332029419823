import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import {
  NoShowPickupType,
  NoShowPickupTypeUID,
  PickupHistory,
  PickupHistoryUID,
} from '../../components/laundry-pickup/types';
import { db } from '../../firebase-config';
import { Employee } from '../../types/types';
// fetching the history of a bin
const getPickupHistoryById = async (uid: string | undefined) => {
  if (uid == undefined) throw Error;
  try {
    const docRef = doc(db, 'pickupHistory', uid);
    // eslint-disable-next-line
    const querySnapshot: any = await getDoc(docRef);

    if (querySnapshot.exists()) {
      const data: PickupHistory = querySnapshot.data();
      return data;
    } else {
      return undefined;
    }
  } catch {
    throw Error;
  }
};

// updating binHistory progress
// eslint-disable-next-line
const updatePickupBagProgress = async (uid: string, data: any) => {
  try {
    const docRef = doc(db, 'pickupHistory', uid);
    await setDoc(docRef, data, { merge: true });
    return true;
  } catch {
    return false;
  }
};

const createSingleNoShowPickupHistory = async (noShowPickup: NoShowPickupType) => {
  try {
    const r = await addDoc(collection(db, 'pickupHistory'), noShowPickup);
    return { success: true, uid: r.id };
  } catch (error) {
    return { success: false, uid: null };
  }
};

const createPickupHistory = async (pickupHistory: PickupHistory[]) => {
  try {
    const batch = writeBatch(db);
    const newUIDs: {
      address: string;
      aptNum: string;
      bagNumber: string;
      city: string;
      postalCode: string;
      state: string;
      name: string;
      notes: string;
      deliveryNotes: string;
      additionalNotes: string;
      uid: string;
      weight: number;
    }[] = [];
    pickupHistory.forEach((ph) => {
      const newPickupHistory = doc(collection(db, 'pickupHistory'));
      batch.set(newPickupHistory, ph);
      const customerName = ph.customer.fullName.split(' ');
      newUIDs.push({
        address: ph.customer.address,
        aptNum: ph.customer.aptNum ? ph.customer.aptNum : '',
        bagNumber: ph.bagNumber,
        city: ph.customer.city,
        postalCode: ph.customer.postalCode,
        state: ph.customer.state,
        name: `${customerName[0]} ${customerName[1][0]}.`,
        notes: ph.notes ? ph.notes : 'N/A',
        deliveryNotes: ph.deliveryNotes ? ph.deliveryNotes : 'N/A',
        additionalNotes: ph.additionalNotes ? ph.additionalNotes : '',
        uid: newPickupHistory.id,
        weight: ph.weight,
      });
    });
    return batch
      .commit()
      .then(() => {
        return newUIDs;
      })
      .catch(() => {
        throw Error;
      });
  } catch (error) {
    throw Error;
  }
};

// this function will create the appropriate query based on the user type
const generateAllPickupHistoryQueryBasedOnEmployee = (user: Employee) => {
  // check to see if they are superadmin
  if (user.status === 'super-admin') {
    return query(collection(db, 'pickupHistory'), where('isDeleted', '==', false));
  }
  // check to see if they are owner or manager
  if (
    user.status === 'business-owner' ||
    (user.status === 'permanent-employee' && user.title === 'manager')
  ) {
    return query(
      collection(db, 'pickupHistory'),
      where('storeUID', '==', user.storeID),
      where('isDeleted', '==', false),
    );
  }
  // check to see if they are driver. only get the pickupHistory for today
  if (user.status == 'permanent-employee' || user.title == 'driver') {
    return query(
      collection(db, 'pickupHistory'),
      where('storeUID', '==', user.storeID),
      where('createdBy.uid', '==', user.uid),
      where('isDeleted', '==', false),
    );
  }
  // if none of them then return null
  return null;
};

// getting all the Pickup bag history
const getAllPickupBagHistory = async (employee: Employee) => {
  try {
    const query = generateAllPickupHistoryQueryBasedOnEmployee(employee);
    if (query !== null) {
      const allPickupBagHistory: (NoShowPickupTypeUID | PickupHistoryUID)[] = [];
      const querySnapshot = await getDocs(query);
      querySnapshot.docs.forEach((d) => {
        const data = d.data();
        // check if it is a noShow or a pickup
        if (data.noShow) {
          const tempNoShowBag: NoShowPickupTypeUID = {
            uid: d.id,
            customer: {
              address: data.customer.address,
              aptNum: data.customer.aptNum,
              city: data.customer.city,
              fullName: data.customer.fullName,
              phoneNumber: data.customer.phoneNumber,
              postalCode: data.customer.postalCode,
              state: data.customer.state,
              uid: data.customer.uid,
            },
            createdAt: data.createdAt,
            createdBy: {
              name: data.createdByname,
              uid: data.createdBy.uid,
            },
            isDeleted: data.isDeleted,
            noShow: data.noShow,
            storeUID: data.storeUID,
          };
          allPickupBagHistory.push(tempNoShowBag);
        } else {
          // we need to format the Data received into the
          // object that will be used to display info.
          // we need to check to make sure it has values for possible missing values
          // add placeholder value
          const tempPickupBag: PickupHistoryUID = {
            uid: d.id,
            bagNumber: data.bagNumber,
            createdAt: data.createdAt,
            createdBy: {
              name: data.createdBy.name ? data.createdBy.name : 'N/A',
              uid: data.createdBy.uid ? data.createdBy.uid : 'N/A',
            },
            customer: {
              address: data.customer.address,
              aptNum: data.customer.aptNum,
              city: data.customer.city,
              fullName: data.customer.fullName,
              phoneNumber: data.customer.phoneNumber,
              postalCode: data.customer.postalCode,
              state: data.customer.state,
              uid: data.customer.uid,
            },
            hasDamagedItems: data.hasDamagedItems,
            isExpressService: data.isExpressService,
            isDeleted: data.isDeleted,
            isSplitBin: data.isSplitBin,
            noShow: data.noShow,
            notes: data.notes,
            additionalNotes: data.additionalNotes,
            progressStatus: {
              delivered: data.progressStatus.delivered
                ? {
                    employee: data.progressStatus.delivered.employee
                      ? {
                          name: data.progressStatus.delivered.employee.name
                            ? data.progressStatus.delivered.employee.name
                            : 'Employee name not found',
                          uid: data.progressStatus.delivered.employee.uid
                            ? data.progressStatus.delivered.employee.uid
                            : null,
                        }
                      : { name: 'Employee data missing', uid: 'ERROR' },
                    timestamp: data.progressStatus.delivered.timestamp
                      ? data.progressStatus.delivered.timestamp
                      : null,
                  }
                : null,
              dried: data.progressStatus.dried
                ? {
                    employee: data.progressStatus.dried.employee
                      ? {
                          name: data.progressStatus.dried.employee.name
                            ? data.progressStatus.dried.employee.name
                            : 'Employee name not found',
                          uid: data.progressStatus.dried.employee.uid
                            ? data.progressStatus.dried.employee.uid
                            : null,
                        }
                      : { name: 'Employee data missing', uid: 'ERROR' },
                    timestamp: data.progressStatus.dried.timestamp
                      ? data.progressStatus.dried.timestamp
                      : null,
                    machine: {
                      number: data.progressStatus.dried.machine.number,
                      uid: data.progressStatus.dried.machine.uid,
                    },
                    additionalServiceNotes: data.progressStatus.dried.additionalServiceNotes
                      ? data.progressStatus.dried.additionalServiceNotes
                      : null,
                  }
                : null,
              foldRack: data.progressStatus.foldRack
                ? {
                    employee: data.progressStatus.foldRack.employee
                      ? {
                          name: data.progressStatus.foldRack.employee.name
                            ? data.progressStatus.foldRack.employee.name
                            : 'Employee name not found',
                          uid: data.progressStatus.foldRack.employee.uid
                            ? data.progressStatus.foldRack.employee.uid
                            : null,
                        }
                      : { name: 'Employee data missing', uid: 'ERROR' },
                    timestamp: data.progressStatus.foldRack.timestamp
                      ? data.progressStatus.foldRack.timestamp
                      : null,
                    endTimestamp: data.progressStatus.foldRack.endTimestamp
                      ? data.progressStatus.foldRack.endTimestamp
                      : null,
                    additionalServiceNotes: data.progressStatus.foldRack.additionalServiceNotes
                      ? data.progressStatus.foldRack.additionalServiceNotes
                      : null,
                  }
                : null,
              pickedUp: data.progressStatus.pickedUp
                ? {
                    employee: data.progressStatus.pickedUp.employee
                      ? {
                          name: data.progressStatus.pickedUp.employee.name
                            ? data.progressStatus.pickedUp.employee.name
                            : 'Employee name not found',
                          uid: data.progressStatus.pickedUp.employee.uid
                            ? data.progressStatus.pickedUp.employee.uid
                            : null,
                        }
                      : { name: 'Employee data missing', uid: 'ERROR' },
                    timestamp: data.progressStatus.pickedUp.timestamp
                      ? data.progressStatus.pickedUp.timestamp
                      : null,
                  }
                : null,
              washed: data.progressStatus.washed
                ? {
                    employee: data.progressStatus.washed.employee
                      ? {
                          name: data.progressStatus.washed.employee.name
                            ? data.progressStatus.washed.employee.name
                            : 'Employee name not found',
                          uid: data.progressStatus.washed.employee.uid
                            ? data.progressStatus.washed.employee.uid
                            : null,
                        }
                      : { name: 'Employee data missing', uid: 'ERROR' },
                    timestamp: data.progressStatus.washed.timestamp
                      ? data.progressStatus.washed.timestamp
                      : null,
                    machine: {
                      number: data.progressStatus.washed.machine.number,
                      uid: data.progressStatus.washed.machine.uid,
                    },
                    additionalServiceNotes: data.progressStatus.washed.additionalServiceNotes
                      ? data.progressStatus.washed.additionalServiceNotes
                      : null,
                  }
                : null,
            },
            storeUID: data.storeUID,
            updatedAt: data.updatedAt,
            weight: data.weight,
          };
          allPickupBagHistory.push(tempPickupBag);
        }
        // deletion and invoice are optional. check if they have a value
      });
      // const filteredDocs = transformFirebaseUsersToFrontendUser(querySnapshot);
      return allPickupBagHistory;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    throw Error;
  }
};

// finalizing a pickup
const finalizePickupBagHistory = async (pickupBag: PickupHistoryUID) => {
  try {
    const docRef = doc(db, 'pickupHistory', pickupBag.uid);
    const data: PickupHistory = {
      bagNumber: pickupBag.bagNumber,
      createdAt: pickupBag.createdAt,
      createdBy: {
        name: pickupBag.createdBy.name,
        uid: pickupBag.createdBy.uid,
      },
      customer: {
        address: pickupBag.customer.address,
        aptNum: pickupBag.customer.aptNum,
        city: pickupBag.customer.city,
        fullName: pickupBag.customer.fullName,
        phoneNumber: pickupBag.customer.phoneNumber,
        postalCode: pickupBag.customer.postalCode,
        state: pickupBag.customer.state,
        uid: pickupBag.customer.uid,
      },
      hasDamagedItems: pickupBag.hasDamagedItems,
      isExpressService: pickupBag.isExpressService,
      isDeleted: pickupBag.isDeleted,
      isSplitBin: pickupBag.isSplitBin,
      noShow: pickupBag.noShow,
      notes: pickupBag.notes,
      progressStatus: {
        pickedUp: pickupBag.progressStatus.pickedUp
          ? {
              employee: {
                name: pickupBag.progressStatus.pickedUp.employee.name,
                uid: pickupBag.progressStatus.pickedUp.employee.uid,
              },
              timestamp: pickupBag.progressStatus.pickedUp.timestamp,
            }
          : null,
        washed: pickupBag.progressStatus.washed
          ? {
              employee: {
                name: pickupBag.progressStatus.washed.employee.name,
                uid: pickupBag.progressStatus.washed.employee.uid,
              },
              timestamp: pickupBag.progressStatus.washed.timestamp,
              machine: {
                number: pickupBag.progressStatus.washed.machine.number,
                uid: pickupBag.progressStatus.washed.machine.uid,
              },
            }
          : null,
        dried: pickupBag.progressStatus.dried
          ? {
              employee: {
                name: pickupBag.progressStatus.dried.employee.name,
                uid: pickupBag.progressStatus.dried.employee.uid,
              },
              timestamp: pickupBag.progressStatus.dried.timestamp,
              machine: {
                number: pickupBag.progressStatus.dried.machine.number,
                uid: pickupBag.progressStatus.dried.machine.uid,
              },
            }
          : null,
        foldRack: pickupBag.progressStatus.foldRack
          ? {
              employee: {
                name: pickupBag.progressStatus.foldRack.employee.name,
                uid: pickupBag.progressStatus.foldRack.employee.uid,
              },
              timestamp: pickupBag.progressStatus.foldRack.timestamp,
              endTimestamp: pickupBag.progressStatus.foldRack.endTimestamp,
            }
          : null,
        delivered: pickupBag.progressStatus.delivered
          ? {
              employee: {
                name: pickupBag.progressStatus.delivered.employee.name,
                uid: pickupBag.progressStatus.delivered.employee.uid,
              },
              timestamp: pickupBag.progressStatus.delivered.timestamp,
            }
          : null,
      },
      storeUID: pickupBag.storeUID,
      updatedAt: pickupBag.updatedAt,
      weight: pickupBag.weight,
    };
    await setDoc(docRef, data, { merge: true });
    return true;
  } catch (error) {
    return false;
  }
};
// deleting a pickup history
const deletePickupHistory = async ({
  pickupBagUID,
  reason,
  user,
}: {
  pickupBagUID: string;
  reason: string;
  user: Employee;
}) => {
  try {
    const docRef = doc(db, 'pickupHistory', pickupBagUID);
    const deletionData = {
      isDeleted: true,
      deletion: {
        reason: reason,
        deletionDate: Timestamp.now(),
        deletedBy: {
          name: `${user.firstName} ${user.lastName}`,
          uid: user.uid ? user.uid : 'N/A',
        },
      },
    };
    await setDoc(docRef, deletionData, { merge: true });
    return true;
  } catch (error) {
    return false;
  }
};

// deleting a pickup history
const deleteNoShowPickupHistory = async (pickupBagUID: string) => {
  try {
    const docRef = doc(db, 'pickupHistory', pickupBagUID);
    const deletionData = {
      isDeleted: true,
    };
    await setDoc(docRef, deletionData, { merge: true });
    return true;
  } catch (error) {
    return false;
  }
};

export {
  createPickupHistory,
  createSingleNoShowPickupHistory,
  deletePickupHistory,
  deleteNoShowPickupHistory,
  finalizePickupBagHistory,
  getAllPickupBagHistory,
  getPickupHistoryById,
  updatePickupBagProgress,
};
