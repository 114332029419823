import { AccountCircle } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Stack,
  Switch,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { createRockBatch, editRock } from '../../../../api/fc-os/rocksAPI';
import { BasicEmployeeInfo } from '../../../../api/fc-os/types/employeeTypes';
import { FirebaseRock, OSRock } from '../../../../api/fc-os/types/rockTypes';
import { Employee, ErrorType } from '../../../../types/types';
import { formatYYYMMDD } from '../../../../utils/formatting';
import { validateMinRequirements } from '../../../../utils/validating';
import { MasterItem } from '../../types';
import s from './style.module.scss';

const MilestoneForm = ({
  user,
  employees,
  handleCancel,
  editItem,
}: {
  user: Employee;
  employees: BasicEmployeeInfo[];
  handleCancel: () => void;
  editItem: MasterItem;
}) => {
  const queryClient = useQueryClient();
  // Input States
  const [title, setTitle] = useState<string>(editItem?.title ?? '');
  const [dueDate, setDueDate] = useState<string>(
    editItem && 'dueDate' in editItem ? formatYYYMMDD((editItem as OSRock).dueDate.toDate()) : '',
  );
  const [description, setDescription] = useState<string>(editItem?.description ?? '');
  const [isPersonal, setIsPersonal] = useState<boolean>(
    editItem && 'isPersonal' in editItem ? editItem.isPersonal : false,
  );
  const [owners, setOwners] = useState<{ name: string; uid: string; isSelected: boolean }[]>(
    setupOwners(),
  );
  // owners drop down menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // ERROR Handling
  const resetErrorMessage = { isError: false, message: '' };
  const [titleError, setTitleError] = useState<ErrorType>(resetErrorMessage);
  const [dueDateError, setDueDateError] = useState<ErrorType>(resetErrorMessage);

  const ITEM_HEIGHT = 48;

  const ERROR_MESSAGES = {
    title: '3 Characters Minimum',
    dueDate: 'Must be correct format dd/mm/yyyy',
  };

  const resetAllValues = () => {
    setTitle('');
    setDueDate('');
    setDescription('');
    setTitleError(resetErrorMessage);
    setDueDateError(resetErrorMessage);
    setOwners(setupOwners());
  };

  // NOTE close form on submit
  const handleClose = () => {
    handleCancel();
    resetAllValues();
  };

  const handleSubmit = async () => {
    try {
      if (editItem) {
        // we have to find the owner that isSelected
        const selectedOwnerIndex = owners.findIndex((owner) => {
          return owner.isSelected === true;
        });
        if (selectedOwnerIndex > -1) {
          const _editItem: OSRock = { ...(editItem as OSRock) };
          _editItem.owner = {
            name: owners[selectedOwnerIndex].name,
            uid: owners[selectedOwnerIndex].uid,
          };
          _editItem.description = description;
          const _dueDate = dayjs(dueDate).unix();
          _editItem.dueDate = new Timestamp(_dueDate, 0);
          _editItem.title = title;
          editRockMutation.mutate(_editItem);
        }
      } else {
        if (
          title.length < 1 ||
          !titleError.isError ||
          dueDate.length < 1 ||
          !dueDateError.isError
        ) {
          // we need to chane to MONTH-DAY-YEAR
          const finalDate = dayjs(dueDate).unix();
          // We need to create a rock for each Owner
          const allRocks: FirebaseRock[] = [];
          owners.forEach((owner) => {
            if (owner.isSelected) {
              const _newRock: FirebaseRock = {
                comments: [],
                position: 0,
                archived: false,
                description: description,
                owner: {
                  name: owner.name,
                  uid: owner.uid,
                },
                createdAt: Timestamp.now(),
                team: {
                  name: 'Fresh Clothes',
                  uid: 'cdscds',
                },
                status: 'On-Track',
                milestones: [],
                title: title,
                dueDate: new Timestamp(finalDate, 0),
                isPersonal: isPersonal,
                isDeleted: false,
                storeUID: user.storeID,
                createdBy: {
                  uid: user.uid ? user.uid : 'noUID',
                  name: `${user.firstName} ${user.lastName}`,
                },
              };
              allRocks.push(_newRock);
            }
          });
          addRockMutation.mutate(allRocks);
        }
      }
    } catch (error) {
      toast.error;
    }
  };

  const handleDateChange = (newDate: string) => {
    setDueDate(newDate);
  };

  function setupOwners(): { uid: string; name: string; isSelected: boolean }[] {
    // we need to find the owner of this Todo
    // CAUTION  - If we are in edit mode then we can only have one owner.
    if (editItem) {
      const _editItem: OSRock = { ...(editItem as OSRock) };
      const finalOwners: { name: string; uid: string; isSelected: boolean }[] = employees.map(
        (owner) => {
          return { ...owner, isSelected: false };
        },
      );

      const ownerIndex = finalOwners.findIndex((employee) => {
        return employee.uid === _editItem.owner.uid;
      });

      if (ownerIndex > -1) {
        const _owner = { ...finalOwners[ownerIndex] };
        _owner.isSelected = true;
        finalOwners[ownerIndex] = _owner;
      }
      return finalOwners;
    } else {
      return employees.map((owner) => {
        return { ...owner, isSelected: false };
      });
    }
  }

  const handleOwnersClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOwnerAvatarClick = (
    user: { name: string; uid: string; isSelected: boolean },
    index: number,
  ) => {
    if (editItem) {
      // copy the lsit of employees
      const finalOwners: { name: string; uid: string; isSelected: boolean }[] = employees.map(
        (owner) => {
          return { ...owner, isSelected: false };
        },
      );
      // find the new selected owner
      const ownerIndex = finalOwners.findIndex((employee) => {
        return employee.uid === user.uid;
      });
      // make that owner the only selected one
      if (ownerIndex > -1) {
        const _owner = { ...finalOwners[ownerIndex] };
        _owner.isSelected = true;
        finalOwners[ownerIndex] = _owner;
      }
      setOwners(finalOwners);
    } else {
      const _user = { ...user };
      _user.isSelected = !_user.isSelected;
      const _owners = [...owners];
      _owners[index] = _user;
      setOwners(_owners);
    }
  };

  // mutation for adding a new Rock
  const addRockMutation = useMutation({
    mutationFn: (rocks: FirebaseRock[]) => createRockBatch(rocks),
    onMutate: async (rocks: FirebaseRock[]) => {
      await queryClient.cancelQueries({ queryKey: ['rocks'] });
      const previousRocks = queryClient.getQueryData<OSRock[]>(['rocks']);
      // if (previousRocks) {
      //   const _newOSRock = {
      //     id: '',
      //     ...rock
      //   }
      //   const _previousRocks = [...previousRocks, _newOSRock]
      //   queryClient.setQueryData<OSRock[]>(
      //     ['rocks'],
      //     _previousRocks
      //   );
      // }
      // Return a context object with the snapshotted value
      return { previousRocks };
    },
    onSuccess: (data, variables, context) => {
      // data is what is returned from the API
      if (context && context.previousRocks && data) {
        const rocksToAdd: OSRock[] = [];
        data.forEach((rd) => {
          // if item is a Team todo then add to the list
          if (!rd.isPersonal || rd.owner.uid === user.uid) {
            rocksToAdd.push(rd);
          }
        });
        queryClient.setQueryData<OSRock[]>(['rocks'], [...context.previousRocks, ...rocksToAdd]);
        resetAllValues();
        handleCancel();
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, rock, context) => {
      if (context?.previousRocks) {
        queryClient.setQueryData(['rocks'], context.previousRocks);
        toast.error('Failed to change todo status');
        resetAllValues();
        handleCancel();
      }
    },
  });
  // edit mutation
  const editRockMutation = useMutation({
    mutationFn: (rock: OSRock) => editRock(rock),
    onMutate: async (rock: OSRock) => {
      await queryClient.cancelQueries({ queryKey: ['rocks'] });
      const previousRocks = queryClient.getQueryData<OSRock[]>(['rocks']);
      if (previousRocks) {
        const _previousRocks = [...previousRocks];
        const foundTodo = _previousRocks.findIndex((r) => {
          return r.id === rock.id;
        });
        if (foundTodo > -1) {
          // find and replace the Rock with the new Rock object passed
          _previousRocks[foundTodo] = rock;
          queryClient.setQueryData<OSRock[]>(['rocks'], _previousRocks);
        }
      }
      // Return a context object with the snapshotted value
      return { previousRocks };
    },
    onSuccess: (data, variables, context) => {
      if (context && context.previousRocks && data) {
        const _previousRocks = [...context.previousRocks];
        const foundTodoIndex = _previousRocks.findIndex((r) => {
          return r.id === data.id;
        });
        if (foundTodoIndex > -1) {
          _previousRocks[foundTodoIndex] = data;
          queryClient.setQueryData<OSRock[]>(['rocks'], _previousRocks);
          handleClose();
          toast.success('Successfully edited Rock!');
        }
      }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, rock, context) => {
      if (context?.previousRocks) {
        queryClient.setQueryData(['rocks'], context.previousRocks);
        toast.error('Failed to change todo status');
      }
    },
  });

  return (
    <>
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            width={'80%'}
            height={'100%'}
          >
            <Box
              width={'10%'}
              height={'100%'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <IconButton
                onClick={handleOwnersClick}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <AccountCircle sx={{ color: 'action.active' }} />
              </IconButton>
            </Box>
            <Menu
              id='long-menu'
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                },
              }}
            >
              {owners.map((user, index) => {
                return (
                  <MenuItem
                    key={`user-${index}`}
                    value={user.name}
                    onClick={() => handleOwnerAvatarClick(user, index)}
                  >
                    <Stack direction={'row'} height={'3vh'} spacing={1}>
                      <Checkbox checked={user.isSelected} color={'primary'} />
                      <Avatar
                        src={'https://i.pravatar.cc/?img=' + user.name}
                        sx={{ width: 25, height: 25 }}
                      ></Avatar>
                      <Typography>{user.name}</Typography>
                    </Stack>
                  </MenuItem>
                );
              })}
            </Menu>
            <Box width={'90%'}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ width: '98%' }}
                required
                label='Name the Rock'
                variant='standard'
                value={title}
                error={titleError.isError}
                helperText={titleError.message}
                onChange={(e) => {
                  setTitle(e.target.value);
                  if (!validateMinRequirements(e.target.value, 3))
                    setTitleError({ isError: true, message: ERROR_MESSAGES.title });
                  else setTitleError(resetErrorMessage);
                }}
              ></TextField>
            </Box>
          </Box>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ mt: 2 }}
            required
            label='Due Date'
            variant='standard'
            type={'date'}
            value={dueDate}
            error={dueDateError.isError}
            helperText={dueDateError.message}
            onChange={(e) => handleDateChange(e.target.value)}
          ></TextField>
        </Box>
        <Box mt={2}>
          <TextareaAutosize
            placeholder='Description'
            minRows={5}
            className={s.textWidth}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          ></TextareaAutosize>
        </Box>
        <Box mt={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box>
            <InputLabel shrink={true}>Team</InputLabel>
            <Typography>Fresh Clothes Leadership</Typography>
          </Box>
          <Box>
            <FormControlLabel
              label='Personal'
              control={
                <Switch
                  value={isPersonal}
                  checked={isPersonal}
                  onChange={() => setIsPersonal(!isPersonal)}
                />
              }
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color='inherit'
          sx={{ px: 2, mx: 2 }}
          variant='contained'
          onClick={() => {
            resetAllValues();
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={
            title.length < 1 || titleError.isError || dueDate.length < 1 || dueDateError.isError
          }
          fullWidth
          type='submit'
          variant='contained'
          onClick={handleSubmit}
        >
          {editItem ? 'Edit' : 'Create'}
        </Button>
      </DialogActions>
    </>
  );
};

export default MilestoneForm;
