import { HashRouter, Route, Routes } from 'react-router-dom';
// import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/layout';
// import IssuePage from './pages/IssuePage';
// import OneOnOnePage from './pages/OneOnOnePage';
// import MeetingPage from './pages/MeetingPage';
import { Suspense, lazy } from 'react';
import LoadingSpinner from './components/common/LoadingSpinner';
import DashboardPage from './pages/DashboardPage';
import LaundryPickupPage from './pages/LaundryPickupPage';
import ProtectedRoute from './utils/protected-route';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
const BinHistoryPage = lazy(() => import('./pages/BinHistoryPage'));
const BinHistoryStatsPage = lazy(() => import('./pages/BinHistoryStatsPage'));
const EmployeesPage = lazy(() => import('./pages/EmployeesPage'));
const InventoryPage = lazy(() => import('./pages/InventoryPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const MachinePage = lazy(() => import('./pages/MachinePage'));
const MessagingPage = lazy(() => import('./pages/MessagingPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const ReceiptsDetailsPage = lazy(() => import('./pages/ReceiptsDetailsPage'));
const ReceiptsPage = lazy(() => import('./pages/ReceiptsPage'));
const RoutesPage = lazy(() => import('./pages/RoutesPage'));
const StorePage = lazy(() => import('./pages/StorePage'));
const UsersPage = lazy(() => import('./pages/UsersPage'));

const App = () => {
  return (
    <HashRouter>
      <Routes>
        {/* PUBLIC ROUTES */}
        <Route path='/login' element={<LoginPage />} />
        <Route path='/forgotpassword' element={<ForgotPasswordPage />} />
        {/* PROTECTED ROUTES */}
        <Route path='/' element={<ProtectedRoute />}>
          <Route path='/' element={<Layout />}>
            <Route
              path='/'
              element={
                <ProtectedRoute
                  status={['super-admin', 'business-owner', 'permanent-employee']}
                  titles={['manager', 'driver', 'washer']}
                />
              }
            >
              <Route path='' element={<DashboardPage />} />
            </Route>
            <Route path='/messaging' element={<ProtectedRoute />}>
              <Route
                path=''
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <MessagingPage />
                  </Suspense>
                }
              />
            </Route>
            {/* <Route path='/qr-code' element={<ProtectedRoute />}>
              <Route path='' element={<QRCodePage />} />
            </Route> */}
            <Route
              path='/laundry-pickup'
              element={
                <ProtectedRoute
                  status={['super-admin', 'business-owner', 'permanent-employee']}
                  titles={['manager', 'driver']}
                />
              }
            >
              <Route
                path=''
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <LaundryPickupPage />
                  </Suspense>
                }
              />
            </Route>
            <Route path='/inventory' element={<ProtectedRoute />}>
              <Route
                path=''
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <InventoryPage />
                  </Suspense>
                }
              />
            </Route>
            <Route path='/store' element={<ProtectedRoute />}>
              <Route
                path=''
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <StorePage />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path='/machines'
              element={
                <ProtectedRoute
                  status={['super-admin', 'business-owner', 'permanent-employee']}
                  titles={['manager']}
                />
              }
            >
              <Route
                path=''
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <MachinePage />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path='/pickup-history'
              element={
                <ProtectedRoute
                  status={['super-admin', 'business-owner', 'permanent-employee']}
                  titles={['manager', 'driver', 'washer']}
                />
              }
            >
              <Route
                path=''
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <BinHistoryStatsPage />
                  </Suspense>
                }
              />
              <Route
                path='/pickup-history/:binUID'
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <BinHistoryPage />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path='/employees'
              element={
                <ProtectedRoute
                  status={['super-admin', 'business-owner', 'permanent-employee']}
                  titles={['manager']}
                />
              }
            >
              <Route
                path=''
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <EmployeesPage />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path='/users'
              element={
                <ProtectedRoute
                  status={['super-admin', 'business-owner', 'permanent-employee']}
                  titles={['manager']}
                />
              }
            >
              <Route
                path=''
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <UsersPage />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path='/receipts'
              element={
                <ProtectedRoute
                  status={['super-admin', 'permanent-employee']}
                  titles={['manager', 'driver', 'washer']}
                />
              }
            >
              <Route path='' element={<ProtectedRoute status={['super-admin']} />}>
                <Route
                  path=''
                  element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <ReceiptsPage />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path='/receipts/:receiptUID'
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <ReceiptsDetailsPage />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path='/routes'
              element={
                <ProtectedRoute
                  status={['super-admin', 'business-owner', 'permanent-employee']}
                  titles={['manager', 'driver']}
                />
              }
            >
              <Route
                path=''
                element={
                  <Suspense fallback={<LoadingSpinner />}>
                    <RoutesPage />
                  </Suspense>
                }
              />
            </Route>
            {/* <Route
              path='/issues'
              element={
                <ProtectedRoute
                  status={['super-admin', 'business-owner', 'permanent-employee']}
                  titles={['manager', 'driver', 'washer']}
                />
              }
            >
              <Route path='' element={<IssuePage />} />
            </Route> */}
            {/* <Route
              path='/meetings'
              element={
                <ProtectedRoute
                  status={['super-admin', 'business-owner', 'permanent-employee']}
                  titles={['manager', 'driver', 'washer']}
                />
              }
            >
              <Route path='' element={<MeetingPage />} />
            </Route> */}
            {/* <Route
              path='/one-on-one'
              element={
                <ProtectedRoute
                  status={['super-admin', 'business-owner', 'permanent-employee']}
                  titles={['manager', 'driver', 'washer']}
                />
              }
            >
              <Route path='' element={<OneOnOnePage />} />
            </Route> */}
          </Route>
        </Route>
        {/* CATCH ALL ROUTE*/}
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </HashRouter>
  );
};

export default App;
