import { Stack } from '@mui/material';
import { Employee } from '../../../../types/types';
import { Dispatch, SetStateAction } from 'react';
import TodosCard from './TodosCard';
import MilestonesCard from './MilestonesCard';
import { RockItem } from '../../types';
import { OSTodo } from '../../../../api/fc-os/types/todos-types';
import { ModalTypes } from '../../createModal/CreateModal.js';
import { OSRock } from '../../../../api/fc-os/types/rockTypes';

type Props = {
  user: Employee;
  setTeamTodosList: Dispatch<
    SetStateAction<
      {
        id: number;
        title: string;
        dueDate: Date;
        status: string;
        description: string;
        type: string;
      }[]
    >
  >;
  setRockTodosList: Dispatch<
    SetStateAction<
      {
        id: number;
        title: string;
        dueDate: Date;
        status: string;
        description: string;
        type: string;
      }[]
    >
  >;
  handleItemEdit: (item: OSTodo | RockItem | OSRock, type: ModalTypes) => void;
};

const Home = ({ user, handleItemEdit }: Props) => {
  return (
    <Stack
      justifyContent={'center'}
      mt={2}
      spacing={{ xs: 1, sm: 2, md: 4 }}
      direction={{ xs: 'column', sm: 'row' }}
    >
      <TodosCard
        user={user}
        title={'Personal To-Dos'}
        type={'personal'}
        handleEditClick={handleItemEdit}
      />
      <TodosCard user={user} title={'Team To-Dos'} type={'team'} handleEditClick={handleItemEdit} />
      <MilestonesCard user={user} title={'Rocks & Milestones'} handleEditClick={handleItemEdit} />
    </Stack>
  );
};

export default Home;
