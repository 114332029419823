import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import s from '../styles.module.scss';
import { validateMinRequirements } from '../../../../../../../utils/validating';
import { ErrorType } from '../../../../../../../types/types';

const DataForm = ({
  // SECTION passing in from dashboard
  insideHandleClose,
  tableData,
}: {
  // SECTION Data Types
  insideHandleClose: () => void;
  tableData: {
    id: number;
    status: string;
    owner: string;
    title: string;
    goal: number | boolean;
    average: number;
    total: number;
    rollingWeeks: {
      weekStart: Date | string;
      weekEnd: Date | string;
      value: number | boolean | string | null;
    }[];
  }[];
}) => {
  const units = ['Currency', 'Percentage', 'Number', 'Yes/No', 'Time'];
  const currencies = ['$', '€'];
  const goalOrentationRates = [
    'Greater than or equal to Goal',
    'Greater than Goal',
    'Equal to Goal',
    'Less than or equal to Goal',
    'Less than Goal',
    'Inside min and max',
    'Outside min and max',
  ];
  const goalOrentations = ['T4W & T13W Goal Calculation', 'T4W Goal Calculation'];
  const measurableOwners = ['Miguel', 'Matt'];

  const yesorNo = ['Yes', 'No'];

  // This is for Priority dropdown scroll
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  // Input States
  const [title, setTitle] = useState<string>('');
  const [goal, setGoal] = useState<number | null>(null);
  const [description, setDescription] = useState<string>('');
  // eslint-disable-next-line
  const [_, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [unit, setUnit] = useState<string>(''); // Yellow console log because of zero
  const [currency, setCurrency] = useState<string>('');
  const [goalOrentationRate, setGoalOrentationRate] = useState<string>('');
  const [goalOrentation, setGoalOrentation] = useState<string>('');
  const [measurableOwner, setMeasurableOwner] = useState<string>('');
  const [yesOrNoValue, setYesOrNoValue] = useState<boolean>(false);
  // const [minGoal, setMinGoal] = useState<number | null>(null)
  // const [maxGoal, setMaxGoal] = useState<number | null>(null)

  //   For Priority
  // const open = Boolean(anchorEl);

  // ERROR Handling
  const resetErrorMessage = { isError: false, message: '' };
  const [titleError, setTitleError] = useState<ErrorType>(resetErrorMessage);
  const [goalError, setGoalError] = useState<ErrorType>(resetErrorMessage);
  const [unitError, setUnitError] = useState<ErrorType>(resetErrorMessage);
  const [goalOrentationRateError, setGoalOrentationRateError] =
    useState<ErrorType>(resetErrorMessage);
  const [goalOrentationError, setGoalOrentationError] = useState<ErrorType>(resetErrorMessage);
  const [measurableOwnerError, setMeasurableOwnerError] = useState<ErrorType>(resetErrorMessage);
  // const [minGoalError, setMinGoalError] = useState<ErrorType>(resetErrorMessage);
  // const [maxGoalError, setMaxGoalError] = useState<ErrorType>(resetErrorMessage);

  const ERROR_MESSAGES = {
    title: '3 Characters Minimum',
    goal: '1 Characters Minimum',
    unit: 'A unit must be chosen',
    min: '1 Characters Minimum',
    max: '1 Characters Minimum',
  };

  const isInsideMinMax = goalOrentationRate === 'Inside min and max';
  const isOutsideMinMax = goalOrentationRate === 'Outside min and max';

  const requiredValue = !(isInsideMinMax || isOutsideMinMax);
  const disabledValue = isInsideMinMax || isOutsideMinMax;

  const resetAllValues = () => {
    setTitle('');
    setGoal(null);
    setUnit('');
    setGoalOrentationRate('');
    setGoalOrentation('');
    setMeasurableOwner('');
    // setMinGoal(null)
    // setMaxGoal(null)
    setYesOrNoValue(false);

    setTitleError(resetErrorMessage);
    setGoalError(resetErrorMessage);
    setUnitError(resetErrorMessage);
    setGoalOrentationRateError(resetErrorMessage);
    setGoalOrentationError(resetErrorMessage);
    setMeasurableOwnerError(resetErrorMessage);
    // setMinGoalError(resetErrorMessage);
    // setMaxGoalError(resetErrorMessage);
  };
  const handleClose = () => {
    insideHandleClose();
  };

  const handleUnit = (unitSelected: string) => {
    if (unitSelected != null) {
      setUnit(unitSelected);
      setAnchorEl(null);
    }
  };

  const handleGoalOrientationRate = (goalOrentationRateSelected: string) => {
    if (goalOrentationRateSelected != null) {
      setGoalOrentationRate(goalOrentationRateSelected);
      setAnchorEl(null);
    }
  };

  const handleGoalOrientation = (goalOrentation: string) => {
    if (goalOrentation != null) {
      setGoalOrentationRate(goalOrentation);
      setAnchorEl(null);
    }
  };

  const handleOwner = (owner: string) => {
    if (owner != null) {
      setMeasurableOwner(owner);
      setAnchorEl(null);
    }
  };

  const handleCurrency = (currency: string) => {
    if (currency != null) {
      setCurrency(currency);
      setAnchorEl(null);
    }
  };

  const handleYesOrNo = (yesOrNoSelected: string) => {
    if (yesOrNoSelected != null) {
      if (yesOrNoSelected == 'Yes') {
        setYesOrNoValue(true);

        return yesOrNoSelected;
      } else {
        setYesOrNoValue(false);
        return yesOrNoSelected;
      }
    }
    console.log('yes no vale', yesOrNoSelected);
  };

  // const handleGoalNumber = (goal: number) => {

  //     processValue(goal)
  //     setGoal(goal)
  // }

  // NOTE generate 13 weeks on create. TODO update 13 with date ranges after creating ranges
  function generateRollingWeeks() {
    const rollingWeeks = [];
    const today = new Date(); // Get today's date
    const currentDate = new Date(today);

    // Create 13 rolling weeks
    for (let i = 0; i < 13; i++) {
      const weekStart = new Date(currentDate);
      const weekEnd = new Date(currentDate.setDate(currentDate.getDate() + 6));

      // Add a new object representing the rolling week
      // TODO create a empty string. OSTODO / OSROCK === Check for reference. In the component first and then global if needed
      rollingWeeks.push({ weekStart, weekEnd, value: '' });

      // Move to the start of the next week for the next iteration
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return rollingWeeks;
  }

  const handleSubmit = async () => {
    try {
      console.log('begin sumb', yesOrNoValue);

      if (
        title.length < 1 ||
        !titleError.isError ||
        unit.length < 1 ||
        !unitError.isError ||
        goalOrentation.length < 1 ||
        !goalOrentationError.isError ||
        goalOrentationRate.length < 1 ||
        !goalOrentationRateError.isError
      ) {
        const newDataRow = {
          id: Math.floor(Math.random() * 100 + 10),
          title: title,
          goal: goal || yesOrNoValue,
          description: description,
          unit: unit,
          goalOrentationRate: goalOrentationRate,
          goalOrentation: goalOrentation,
          currency: currency,
          owner: measurableOwner,
          status: 'On-Track',
          average: 0,
          total: 0,
          rollingWeeks: generateRollingWeeks(),
        };

        if (goal == null) {
          newDataRow.goal = yesOrNoValue;
        } else {
          newDataRow.goal = goal;
        }
        // 0 might be wrong maybe -1
        // if (goal == 0) {
        //   newDataRow.goal = yesOrNoValue
        // }else{

        // }

        console.log(newDataRow.goal, 'Check to see if it changes between true and string');

        console.log(newDataRow, 'created this issue');
        // console.log(tableData, '[Before push new issues]');

        // TODO  tableData.push(newDataRow) TODO Replace with push to new form after creating item properly
        tableData.push(newDataRow);
        // console.log(tableData, '[After push new issues]');

        resetAllValues();
        insideHandleClose();
      } else {
        toast.error(ERROR_MESSAGES.title);
      }
    } catch (error) {
      toast.error;
    }
  };

  return (
    <>
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
          <PersonAddIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />

          <TextField
            sx={{ mt: 2 }}
            required
            fullWidth
            label='Title'
            variant='standard'
            error={titleError.isError}
            helperText={titleError.message}
            onChange={(e) => {
              setTitle(e.target.value);
              if (!validateMinRequirements(e.target.value, 3))
                setTitleError({ isError: true, message: ERROR_MESSAGES.title });
              else setTitleError(resetErrorMessage);
            }}
          ></TextField>
        </Box>
        <Box mt={2}>
          <TextareaAutosize
            placeholder='Description'
            minRows={5}
            className={s.textWidth}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          ></TextareaAutosize>
        </Box>
        <Box sx={{ my: 3 }} display={'flex'} alignItems={'center'}>
          <Switch></Switch>
          <Typography variant='subtitle1'>
            <b>Smart Measurables</b>
          </Typography>
        </Box>
        <Box>
          <Typography>
            Smart Measurables allow you to auto-fill scores for this Measurable by combining
            existing Measurable scores via a formula.
          </Typography>
        </Box>
        {/* SECTION All The Dropdown menus for this form starts here */}
        <Box mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-around'}>
          <FormControl variant='standard' sx={{ minWidth: 120 }}>
            <InputLabel id='unit-label'>Unit</InputLabel>
            <Select
              sx={{ mt: 2, maxWidth: '6vw', minWidth: '6vw' }}
              labelId='unit-label'
              id='unit-standard'
              //   value={unit}
              label='Unit'
              MenuProps={MenuProps}
            >
              {/* TODO Change out this out with unit information */}
              {units.map((unit) => {
                return (
                  <MenuItem key={unit} value={unit} onClick={() => handleUnit(unit)}>
                    {unit}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {unit == 'Currency' ? (
            <FormControl variant='standard' sx={{ minWidth: 120 }}>
              <InputLabel id='Currency-label'>Currency</InputLabel>
              <Select
                sx={{ mt: 2, maxWidth: '6vw', minWidth: '6vw' }}
                labelId='currency-label'
                id='currency-standard'
                //   value={unit}
                label='Unit'
                MenuProps={MenuProps}
              >
                {/* TODO Change out this out with unit information */}
                {currencies.map((currencyType) => {
                  return (
                    <MenuItem
                      key={currencyType}
                      value={currencyType}
                      onClick={() => handleCurrency(currencyType)}
                    >
                      {currencyType}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            ''
          )}
          {unit == 'Yes/No' ? (
            <FormControl variant='standard' fullWidth>
              <InputLabel id='yesorno-label'>Goal</InputLabel>
              <Select
                labelId='yesorno-label'
                id='yesorno-standard'
                //   value={unit}
                label='Unit'
                MenuProps={MenuProps}
              >
                {/* TODO Change out this out with unit information */}
                {yesorNo.map((yesorNoSelected) => {
                  return (
                    <MenuItem
                      key={yesorNoSelected}
                      value={yesorNoSelected}
                      onClick={() => handleYesOrNo(yesorNoSelected)}
                    >
                      {yesorNoSelected}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            <TextField
              required={requiredValue ? true : false}
              disabled={disabledValue ? true : false}
              fullWidth
              // NOTE this value should work and erase what the person route if selected min our outside values
              // else it will be the e.target.value
              value={disabledValue ? '' : goal}
              label='Goal'
              variant='standard'
              error={goalError.isError}
              helperText={goalError.message}
              onChange={(e) => {
                // if(disabledValue ? e.target.value = '' : e.target.value)
                setGoal(parseInt(e.target.value));
                if (!validateMinRequirements(e.target.value, 1))
                  setGoalError({ isError: true, message: ERROR_MESSAGES.title });
                else setGoalError(resetErrorMessage);
              }}
            ></TextField>
          )}
        </Box>
        {/* SECTION 2nd Form Row */}
        <Box mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <FormControl variant='standard' fullWidth>
            <InputLabel variant='standard' htmlFor='uncontrolled-native'>
              {' '}
              Goal Orientation Rule
            </InputLabel>

            <Select
              sx={{ mt: 2, mr: 1 }}
              labelId='goal-orientation-label'
              id='goal-orientation-standard'
              label='Goal Orientation Rule'
              MenuProps={MenuProps}
              value={unit !== 'Yes/No' ? goalOrentationRate : goalOrentationRates[2]} // Set the default value to Equal to Goal when unit is 'Yes/No'
              disabled={unit === 'Yes/No'} // Disable when unit is 'Yes/No'
            >
              {/* TODO Change out this out with unit information */}

              {goalOrentationRates.map((goalRate) => {
                return (
                  <MenuItem
                    key={goalRate}
                    value={goalRate}
                    onClick={() => handleGoalOrientationRate(goalRate)}
                  >
                    {goalRate}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl variant='standard' fullWidth>
            <InputLabel id='goal-orientation-label'>T4W Goal Calculation</InputLabel>
            <Select
              sx={{ mt: 2, mr: 1 }}
              labelId='goal-orientation-label'
              id='goal-orientation-standard'
              //   value={unit}

              label='Goal Orientation Rule'
              MenuProps={MenuProps}
            >
              {/* TODO Change out this out with unit information */}
              {goalOrentations.map((goalOrentation) => {
                return (
                  <MenuItem
                    key={goalOrentation}
                    value={goalOrentation}
                    onClick={() => handleGoalOrientation(goalOrentation)}
                  >
                    {goalOrentation}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        {/* SECTION 3rd Form Row / ONLY if inside / outside max chosen */}
        {goalOrentationRate === 'Inside min and max' ||
        goalOrentationRate === 'Outside min and max' ? (
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <TextField
              sx={{ mt: 2, mr: 1 }}
              fullWidth
              id='standard-number'
              label='Min'
              type='number'
              required
              variant='standard'
              // onChange={(e) => {
              //          setMinGoal(parseInt(e.target.value))
              //          if (!validateMinRequirements(e.target.value, 1))
              //           setMinGoalError({ isError: true, message: ERROR_MESSAGES.title });
              //       else setMinGoalError(resetErrorMessage)
              //          ;}}
            />
            <TextField
              sx={{ mt: 2, mr: 1 }}
              fullWidth
              id='standard-number'
              label='Max'
              type='number'
              required
              variant='standard'
              // onChange={(e) => {
              //          setMaxGoal(parseInt(e.target.value))
              //          if (!validateMinRequirements(e.target.value, 1))
              //           setMaxGoalError({ isError: true, message: ERROR_MESSAGES.title });
              //       else setMaxGoalError(resetErrorMessage)
              //          ;}}
            />
          </Box>
        ) : (
          ''
        )}

        {/* SECTION 4rd Form Row */}
        <Box mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <FormControl variant='standard' fullWidth>
            <InputLabel id='measurable-owner-label'>Measurable Owner</InputLabel>
            <Select
              sx={{ mt: 2, mr: 1 }}
              labelId='measurable-owner-label'
              id='measurable-owner-standard'
              label='Measurable Owner'
              MenuProps={MenuProps}
            >
              {/* TODO Change out this out with unit information */}
              {measurableOwners.map((owner) => {
                return (
                  <MenuItem key={owner} value={owner} onClick={() => handleOwner(owner)}>
                    {owner}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color='inherit' sx={{ px: 2, mx: 2 }} variant='contained' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={
            title.length < 1 ||
            titleError.isError ||
            measurableOwner.length < 1 ||
            measurableOwnerError.isError ||
            goalOrentationRate.length < 1 ||
            goalOrentationRateError.isError ||
            unit.length < 1 ||
            unitError.isError
          }
          fullWidth
          type='submit'
          variant='contained'
          onClick={handleSubmit}
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default DataForm;
