import { Box, Stack, Typography } from '@mui/material';
import { PickupBin } from '../types';
import CallSplitIcon from '@mui/icons-material/CallSplit';

type Props = {
  binData: PickupBin;
  isSplit: boolean;
};

const PickupBinReview = ({ binData, isSplit }: Props) => {
  return (
    <Stack border={'1px solid #cccccc'} mt={2} p={1} spacing={1} bgcolor={'#eeeeee'}>
      <Box display={'flex'} flexDirection={'row'}>
        <Box display={'flex'} flexDirection={'row'} flex={1} gap={1}>
          <Typography fontWeight={'bold'}>Bin Number:</Typography>
          <Typography>{binData.binNumber}</Typography>
        </Box>
        {isSplit ? (
          <Box display={'flex'} flexDirection={'row'} justifyContent={'right'} gap={1} flex={1}>
            <CallSplitIcon />
            <Typography color={'orange'}>Split bin</Typography>
          </Box>
        ) : null}
      </Box>
      <Box display={'flex'} flexDirection={'row'} gap={1}>
        <Typography fontWeight={'bold'}>Bin Weight:</Typography>
        <Typography> {binData.weight} lbs</Typography>
      </Box>
      {binData.notes ? (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Typography fontWeight={'bold'}>Cleaning Notes:</Typography>
          <Typography>{binData.notes}</Typography>
        </Box>
      ) : null}
      {binData.deliveryNotes ? (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Typography fontWeight={'bold'}>Delivery Notes:</Typography>
          <Typography>{binData.deliveryNotes}</Typography>
        </Box>
      ) : null}
      {binData.additionalNotes ? (
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          <Typography fontWeight={'bold'}>Additional Notes:</Typography>
          <Typography>{binData.additionalNotes}</Typography>
        </Box>
      ) : null}
    </Stack>
  );
};

export default PickupBinReview;
