import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import MinimizeIcon from '@mui/icons-material/Minimize';
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { MouseEvent, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getBasicEmployee } from '../../../api/fc-os/employeesAPI';
import { Employee } from '../../../types/types';
import { IssueItem, MasterItem } from '../types';
import HeadlineFrom from './Forms/HeadlineForm';
import IssueForm from './Forms/IssueForm';
import RockForm from './Forms/MilestoneForm';
import TodoForm from './Forms/TodoForm';

const MODAL_TYPES = ['To-Do', 'Rock', 'Issue', 'Headline'] as const;
export type ModalTypes = typeof MODAL_TYPES[number] | null;

export type HandleModalEditCreate = (opts: {
  type: 'edit' | 'create';
  item?: MasterItem;
  itemType?: ModalTypes;
  setIndex?: number;
}) => void;

type Props = {
  user: Employee;
  itemToEdit: MasterItem;
  editItemType: ModalTypes;
  closeDialog: () => void;
  resetAfterSuccesfulEdit: () => void;
  setIndex?: number;
  // handleEditSubmit: (newItem: TodoItem | RockItem, type: string) => void;
};
const ITEM_HEIGHT = 48;

const CreateModal = ({
  user,
  itemToEdit,
  editItemType,
  closeDialog,
  resetAfterSuccesfulEdit,
  setIndex,
}: Props) => {
  // grabbing basic Employee information for drop down menu
  // loading the data
  const { data: employeeData } = useQuery(['employeesOwnerInfo'], () =>
    getBasicEmployee(user.storeID),
  );
  // the state of the modal
  const [selectedTypeIndex, setSelectedTypeIndex] = useState<number>(setIndex ?? 0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  // NOTE this opens the Forum type so you can choose
  const handleTypeClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (itemToEdit) {
      if (editItemType === 'To-Do') {
        setSelectedTypeIndex(0);
      } else if (editItemType === 'Rock') {
        setSelectedTypeIndex(1);
      } else if (editItemType === 'Issue') {
        setSelectedTypeIndex(2);
      } else {
        setSelectedTypeIndex(3);
      }
    }
  }, [itemToEdit]);

  return (
    <Dialog open={true} maxWidth={'sm'} fullWidth onClose={closeDialog}>
      <DialogTitle boxShadow={2} borderBottom={'1px solid darkgray'}>
        <Grid container alignItems={'center'}>
          <Grid item xs={8}>
            <Stack direction={'row'} alignItems={'center'}>
              <Typography fontSize={{ xs: 18 }}>{itemToEdit ? 'Edit' : 'Create'}</Typography>
              <Button onClick={handleTypeClick}>
                <Typography fontSize={{ xs: 18 }} fontWeight={'bold'} textTransform={'capitalize'}>
                  {MODAL_TYPES[selectedTypeIndex]}
                </Typography>
              </Button>
              <ArrowDropDownIcon fontSize='medium'></ArrowDropDownIcon>
            </Stack>
            <Menu
              id='long-menu'
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
                },
              }}
            >
              {MODAL_TYPES.map((type, index) => {
                return (
                  <MenuItem
                    key={type}
                    value={index}
                    onClick={() => {
                      setSelectedTypeIndex(index);
                      setAnchorEl(null);
                    }}
                  >
                    {type}
                  </MenuItem>
                );
              })}
            </Menu>
          </Grid>
          <Grid item xs={4}>
            <Stack direction={'row'} justifyContent={'right'}>
              <IconButton>
                <MinimizeIcon></MinimizeIcon>
              </IconButton>
              <IconButton>
                <CloseFullscreenIcon></CloseFullscreenIcon>
              </IconButton>
              <IconButton onClick={closeDialog}>
                <CloseIcon></CloseIcon>
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </DialogTitle>
      {selectedTypeIndex === 0 && employeeData && (
        <TodoForm
          user={user}
          employees={employeeData}
          handleCancel={closeDialog}
          item={itemToEdit}
          resetAfterSuccessfulEdit={resetAfterSuccesfulEdit}
        />
      )}
      {selectedTypeIndex === 1 && employeeData && (
        <RockForm
          user={user}
          employees={employeeData}
          handleCancel={closeDialog}
          editItem={itemToEdit}
        />
      )}
      {selectedTypeIndex === 2 && (
        <IssueForm handleCancel={closeDialog} item={itemToEdit as IssueItem} />
      )}
      {selectedTypeIndex === 3 && <HeadlineFrom handleCancel={closeDialog} />}
    </Dialog>
  );
};

export default CreateModal;
