import {
  useTheme,
  Avatar,
  Card,
  Grid,
  IconButton,
  Typography,
  Hidden,
  Stack,
  Box,
} from '@mui/material';
import StatusMenu from '../../StatusMenu';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { formatDateMonthDayYear } from '../../../../../../utils/formatting';
import { Todo } from '../../../../../../types/types';
import { RockItem, TodoItem } from '../../../../types';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ModalTypes } from '../../../../createModal/CreateModal.js';
import { OSRock, RockStatus } from '../../../../../../api/fc-os/types/rockTypes';

type Props = {
  rockTodo: OSRock;
  deleteRock: (rockTodo: OSRock) => void;
  onStatusChange: (id: string, newStatus: RockStatus) => void;
  handleEditClick: (item: TodoItem | OSRock, type: ModalTypes) => void;
};

const CompanyRockRow = ({ rockTodo, deleteRock, onStatusChange, handleEditClick }: Props) => {
  const theme = useTheme();
  const lightGray = theme.palette.primary.light;

  const deleteRockItem = (rockTodo: OSRock) => {
    deleteRock(rockTodo);
  };

  return (
    <Card
      sx={{
        py: 1,
        px: 1,
        cursor: 'pointer',
        '&:hover': { backgroundColor: lightGray, '.drag-icon': { opacity: 1 } },
      }}
    >
      <Grid container alignItems={'center'}>
        <Grid item xs={2}>
          <Stack direction={'row'}>
            <Box className='drag-icon' sx={{ opacity: 0 }}>
              <DragIndicatorIcon htmlColor={theme.palette.primary.dark} />
            </Box>
            <StatusMenu rock={rockTodo} onStatusChange={onStatusChange} />
          </Stack>
        </Grid>
        <Grid item xs={7} alignItems={'center'} display={'flex'}>
          <Typography>{rockTodo.title}</Typography>
          <IconButton onClick={() => handleEditClick(rockTodo, 'Rock')}>
            <EditIcon
              htmlColor={lightGray}
              sx={{
                opacity: 0,
                '&:hover': {
                  opacity: 1,
                },
              }}
            ></EditIcon>
          </IconButton>
        </Grid>
        <Grid item xs={2}>
          <Typography>{formatDateMonthDayYear(rockTodo.dueDate.toDate().toString())}</Typography>
        </Grid>
        <Grid item display={'flex'} alignItems={'center'} justifyContent={'space-around'} xs={1}>
          <Hidden mdDown>
            <Avatar src={'https://i.pravatar.cc/?img=' + `${rockTodo.id}`}></Avatar>
          </Hidden>
          <IconButton onClick={() => deleteRockItem(rockTodo)}>
            <DeleteIcon htmlColor='red'></DeleteIcon>
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CompanyRockRow;
