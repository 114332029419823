import { Button, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

type Props = {
  orderByProp: string;
  activeOrderRow: string;
  setOrder: (newOrder: number, orderByProp: string) => void;
};

const ArrowOrdering = ({ setOrder, orderByProp, activeOrderRow }: Props) => {
  const [position, setPosition] = useState<number>(0);
  const handleButtonClick = () => {
    const _position = (position + 1) % 3;
    setPosition(_position);
    setOrder(_position, orderByProp);
  };

  useEffect(() => {
    if (activeOrderRow !== orderByProp) {
      setPosition(0);
    }
  }, [activeOrderRow]);
  return (
    <IconButton style={{ padding: 0 }} onClick={handleButtonClick}>
      {position === 0 && (
        <ArrowUpwardIcon
          fontSize='small'
          sx={{
            opacity: 0,
            '&:hover': {
              opacity: 1,
            },
          }}
        />
      )}
      {position === 1 && <ArrowUpwardIcon fontSize='small' />}
      {position === 2 && <ArrowDownwardIcon fontSize='small' />}
    </IconButton>
  );
};

export default ArrowOrdering;
