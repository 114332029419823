import { Box, Dialog, DialogTitle, IconButton } from '@mui/material';
import s from '../DataModal/styles.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import DataForm from './Forms/DataForm';

// const ITEM_HEIGHT = 48;

const DataModal = ({
  isOpen,
  onClose,
  tableData,
}: {
  isOpen: boolean;
  onClose: () => void;
  tableData: {
    id: number;
    status: string;
    owner: string;
    title: string;
    goal: number | boolean;
    average: number;
    total: number;
    rollingWeeks: {
      weekStart: Date | string;
      weekEnd: Date | string;
      value: number | boolean | string | null;
    }[];
  }[];
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  //   const [moduleInView, setModuleInView] = useState<boolean>(false); // Pass the state to the forms but close it in the parent
  const open = Boolean(anchorEl);
  // const [Type, setType] = useState<string>('Yes/No');

  // NOTE this opens the Forum type so you can choose
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // NOTE Sets the anchor inside the modal to null and runs the Parent close modal function
  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };
  return (
    <Box>
      <Dialog maxWidth={'sm'} fullWidth open={isOpen} onClose={handleClose}>
        <Box
          sx={{ borderBottom: '1px solid darkgray', boxShadow: 2 }}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <DialogTitle>
            Create
            <Box
              component='span'
              ml={0.5}
              aria-label='more'
              id='long-button'
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup='true'
              onClick={handleClick}
            >
              Create and Add Measurable
            </Box>
          </DialogTitle>
          <Box sx={{ m: { md: 2 } }}>
            <IconButton onClick={handleClose} className={s.dark}>
              <CloseIcon></CloseIcon>
            </IconButton>
          </Box>
        </Box>

        <>
          {/* SECTION for Different Types of Data forms go here if needed */}
          <DataForm tableData={tableData} insideHandleClose={handleClose} />
        </>
      </Dialog>
    </Box>
  );
};

export default DataModal;
