import { Dialog, DialogContent } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { LoaderIcon, toast } from 'react-hot-toast';
import { createReceipt } from '../../../../api/receipts';
import { useAuth } from '../../../../context/AuthContext';
import { Employee, Receipt, User } from '../../../../types/types';
import ConfirmDialog from '../../../common/ConfirmDialog';
import QRModalDialogTitle from '../common/QRModalDialogTitle';
import ItemsSelection from './ItemsSelection';
import ReceiptReview from './ReceiptReview';
import TagsItemSelect from './TagsItemSelect';
import { ReceiptItem } from './types';
import { Timestamp } from 'firebase/firestore';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  user: User;
};

const CorporateReceipt = ({ user, isOpen, setIsOpen }: Props) => {
  const { user: loggedInUser }: { user: Employee } = useAuth();
  const todaysDate = new Date().toUTCString();
  // exit confirmation dialog
  const [confirmDialogInView, setConfirmDialogInView] = useState<boolean>(false);
  // these values will come from the child components
  const [completeItemsList, setCompleteItemsList] = useState<ReceiptItem[] | null>(null);
  const [receiptItemsTotals, setReceiptItemsTotals] = useState<{
    [key: string]: { total: number; label: string; dryTotal?: number };
  } | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [createdReceiptUID, setCreatedReceiptUID] = useState<string | null>(null);
  // saving the receipt

  // a confirmation dialog box will appear before close the receipt modal
  const handleClose = (response: boolean) => {
    if (response) {
      setIsOpen(false);
    }
    setConfirmDialogInView(false);
  };
  // function for moving to the next step
  const moveToNextStep = () => {
    setCurrentStep(currentStep + 1);
  };
  // function for saving the receipt
  const handleSaveClick = async () => {
    if (completeItemsList == null) {
      return;
    }

    setCurrentStep(2);
    const newReceiptData: Receipt = {
      billTo: {
        address: user.address,
        city: user.city,
        fullName: user.fullName,
        postalcode: user.postalcode,
        state: user.state,
        email: user.email,
      },
      createdAt: Timestamp.now(),
      createdBy: {
        fullName: `${loggedInUser.firstName} ${loggedInUser.lastName}`,
        email: loggedInUser.email,
        storeUID: loggedInUser.storeID ? loggedInUser.storeID : '',
        uid: loggedInUser.uid ? loggedInUser.uid : '',
      },
      dueDate: '',
      invoiceDate: '',
      invoiceNumber: 0,
      itemsList: completeItemsList,
      leasingItems: user.leasingItems ? true : false,
      salesTax: 0,
      subtotal: 0,
      termsAndConditions: [],
      totalPrice: 0,
      totals: receiptItemsTotals ? receiptItemsTotals : {},
      updatedAt: Timestamp.now(),
      userUID: user.docID, // this is the selected User to create the receipt for
      type: user.receiptPickupType ? user.receiptPickupType : 'itemized',
    };

    const success = await createReceipt(newReceiptData);
    if (success) {
      //   setSuccessfulSave(true);
      setCreatedReceiptUID(success);
      setCurrentStep(3);
    } else {
      toast.error('Failed to save receipt. Please try again.');
    }
  };
  // function for editing the receipt
  const handleEditClick = () => {
    setCurrentStep(0);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => setConfirmDialogInView(true)} fullWidth>
        <QRModalDialogTitle
          fullname={user.fullName}
          address={user.address}
          apt={user.apt ? user.apt : null}
          city={user.city}
          state={user.state}
          postalcode={user.postalcode}
        />
        <DialogContent sx={{ padding: 2 }}>
          {currentStep == 0 && user.itemsList ? (
            <ItemsSelection
              trackWetDryWeight={user.trackWetDryWeight ? user.trackWetDryWeight : false}
              todaysDate={todaysDate.slice(0, 16)}
              moveToNextStep={moveToNextStep}
              itemsList={user.itemsList}
              alreadySelectedItems={completeItemsList}
              alreadySelectedTotals={receiptItemsTotals}
              sendItemsListToParent={setCompleteItemsList}
              sendReceiptTotalsToParent={setReceiptItemsTotals}
            />
          ) : null}
          {currentStep == 1 && completeItemsList ? (
            <ReceiptReview
              todaysDate={todaysDate.slice(0, 16)}
              user={user}
              items={completeItemsList}
              totals={receiptItemsTotals}
              handleEditClick={handleEditClick}
              handleSaveClick={handleSaveClick}
            />
          ) : null}
          {currentStep == 2 ? <LoaderIcon style={{ height: '50px', width: '50px' }} /> : null}
          {currentStep == 3 && completeItemsList && createdReceiptUID ? (
            <TagsItemSelect
              receiptUID={createdReceiptUID}
              itemsList={completeItemsList}
              userData={{
                name: user.fullName,
                address: `${user.address} ${user.apt} ${user.city} ${user.state} ${user.postalcode}`,
                notes: user.notes ? user.notes : '',
                trackDryWetWeight: user.trackWetDryWeight ? user.trackWetDryWeight : false,
              }}
            />
          ) : null}
        </DialogContent>
      </Dialog>
      {confirmDialogInView && (
        <ConfirmDialog
          title={'Are you sure you would like to close the receipt creation?'}
          message={'Data will be lost'}
          handleClick={handleClose}
        />
      )}
    </>
  );
};

export default CorporateReceipt;
