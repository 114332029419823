import { AccountCircle } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import s from './style.module.scss';

import { ErrorType } from '../../../../types/types';
import { validateMinRequirements } from '../../../../utils/validating';
import { IntervalType, IssueItem, PriorityType } from '../../types';
// import { useIssueContext } from '../../../../context/IssuesContext';
// import { issuesData } from '../../Issues/utils/IssueData';

const IssueForm = ({
  // SECTION passing in from dashboard
  // isOpen,
  handleCancel,
  item,
}: {
  // SECTION Data Types
  // isOpen: boolean;
  handleCancel: () => void;
  item: IssueItem;
}) => {
  const terms: IntervalType[] = ['Short-Term', 'Long-Term'];
  const priorities: PriorityType[] = ['None', '1', '2', '3', '4', '5'];

  // This is for Priority dropdown scroll
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  // Input States
  const [title, setTitle] = useState<string>('');
  //   const [dueDate, setDueDate] = useState<string>('')
  // const [status, setStatus] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  // const [isPersonal, setIsPersonal] = useState<boolean>(false);
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [term, setTerm] = useState<IntervalType>('Short-Term');
  const [priority, setPriority] = useState<PriorityType>('None'); // Yellow console log because of zero

  //   For Priority
  // const open = Boolean(anchorEl);

  useEffect(() => {
    if (item) {
      // console.log(item)
      setTitle(item.title);
      setDescription(item.description ?? '');
      setTerm(item.interval);
      setPriority(item.priority ?? 'None');
    }
  }, []);

  // ERROR Handling
  const resetErrorMessage = { isError: false, message: '' };
  const [titleError, setTitleError] = useState<ErrorType>(resetErrorMessage);
  const [termError, setTermError] = useState<ErrorType>(resetErrorMessage);
  const [priorityError] = useState<ErrorType>(resetErrorMessage);
  // const { issuesList: issues, setIssuesList } = useIssueContext();

  const ERROR_MESSAGES = {
    title: '3 Characters Minimum',
    term: 'A Interval must be chosen',
    priority: 'A Priority must be chosen',
  };

  const resetAllValues = () => {
    setTitle('');
    setTerm('Short-Term');

    setTitleError(resetErrorMessage);
    setTermError(resetErrorMessage);
  };

  const handleClose = () => {
    // Might need more logic here confused what
    // isLoading was doing and might be needed
    // setAnchorEl(null);
    handleCancel();
  };

  const handleTerm = (termSelected: IntervalType) => {
    if (termSelected != null) {
      setTerm(termSelected);
      // setAnchorEl(null);
    }
  };

  const handlePriority = (prioritySelected: PriorityType) => {
    setPriority(prioritySelected);
    // setAnchorEl(null);
  };

  const handleSubmit = async () => {
    try {
      if (
        title.length < 1 ||
        !titleError.isError ||
        term.length < 1 ||
        !termError.isError ||
        // priority == '' ||
        !priorityError.isError
      ) {
        // const newIssue: IssueItem = {
        //   id: Math.floor(Math.random() * 100 + 10),
        //   title: title,
        //   interval: term, // TODO Come back later and decide name interval or term
        //   description: description,
        //   team: 'Fresh Clothes',
        //   priority: priority,
        //   archived: false
        // };
        // if (issues) {
        //   setIssuesList([...issues, newIssue])
        // } else {
        //   setIssuesList([newIssue])
        // }
        resetAllValues();
        handleCancel();
      } else {
        toast.error(ERROR_MESSAGES.title);
      }
    } catch (error) {
      toast.error;
    }
  };

  return (
    <>
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
          <Box display={'flex'} alignItems={'end'}>
            <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ mt: 2, maxWidth: '25vw', minWidth: '20vw' }}
              required
              label='Name the Issue'
              variant='standard'
              value={title}
              error={titleError.isError}
              helperText={titleError.message}
              onChange={(e) => {
                setTitle(e.target.value);
                if (!validateMinRequirements(e.target.value, 3))
                  setTitleError({ isError: true, message: ERROR_MESSAGES.title });
                else setTitleError(resetErrorMessage);
              }}
            ></TextField>
          </Box>
          {/* SECTION INTERVAL / TERM Choice */}
          <FormControl variant='standard' sx={{ mx: 1, minWidth: 120 }}>
            <InputLabel required shrink={true} id='term-label'>
              Interval
            </InputLabel>
            <Select
              labelId='term-label'
              id='term-selected'
              value={term}
              //   defaultValue={1}
              onChange={(e) => {
                setTerm(e.target.value as IntervalType);
                if (!validateMinRequirements(e.target.value, 3))
                  setTermError({ isError: true, message: ERROR_MESSAGES.title });
                else setTermError(resetErrorMessage);
              }}
              label='Interval'
            >
              {terms.map((term) => {
                return (
                  <MenuItem key={term} value={term} onClick={() => handleTerm(term)}>
                    {term}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          {/* <TextField
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ mt: 2, ml: 2 }}
        required
        label='Due Date'
        variant='standard'
        type={'date'}
        value={dueDate}
        error={dueDateError.isError}
        helperText={dueDateError.message}
        onChange={(e) => handleDateChange(e.target.value)}
      
      ></TextField> */}
        </Box>
        <Box mt={2}>
          <TextareaAutosize
            placeholder='Description'
            minRows={5}
            className={s.textWidth}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          ></TextareaAutosize>
        </Box>
        <Box mt={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box>
            <InputLabel shrink={true}>Team</InputLabel>
            <Typography>Fresh Clothes Leadership</Typography>
          </Box>
          <Box>
            {/* SECTION Priority Drop Down */}
            <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id='priority-label'>Priority</InputLabel>
              <Select
                sx={{ mt: 2, maxWidth: '25w', minWidth: '16vw' }}
                labelId='priority-label'
                id='priority-standard'
                value={priority}
                //   defaultValue={1}
                //  onChange={(e) => handlePriorityChange(parseInt(e.target.value))}
                label='Priority'
                MenuProps={MenuProps}
              >
                <MenuItem key={''} value={''} onClick={() => handlePriority('None')}>
                  None
                </MenuItem>
                {priorities.map((priority) => {
                  return (
                    <MenuItem
                      key={priority}
                      value={priority}
                      onClick={() => handlePriority(priority)}
                    >
                      {priority}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color='inherit' sx={{ px: 2, mx: 2 }} variant='contained' onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={title.length < 1 || titleError.isError || term.length < 1 || termError.isError}
          fullWidth
          type='submit'
          variant='contained'
          onClick={handleSubmit}
        >
          Create
        </Button>
      </DialogActions>
    </>
  );
};

export default IssueForm;
